import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';

import { AllocationHistory } from '@/constants/AllocationConstants';
import api from '@/services/apiSgft';
import { DeallocationEditData } from '@/state/AllocationDataType.atom';
import { AllocationEditData } from '@/state/AllocationEditData';
import { AllocationTypeAtom } from '@/state/AllocationType.atom';
import { removeTimeZone } from '@/utils/formatDate';

import AllocationMenu, { AllocationMenuOptions } from '../atoms/AllocationMenu';
import ModalConfirmation from './ConfirmationModal';

interface Props {
  allocation: AllocationHistory;
}

const AllocationButtons = ({ allocation }: Props) => {
  const queryClient = useQueryClient();
  const setType = useSetRecoilState(AllocationTypeAtom);
  const setEditData = useSetRecoilState(AllocationEditData);
  const setDeallocationEditData = useSetRecoilState(DeallocationEditData);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [deleteType, setDeleteType] = useState<
    'deallocation' | 'reallocation' | ''
  >('');

  const handleDelete = async () => {
    try {
      const response = await api.delete(
        `/team-employee?employeeTeamHistoryId=${Number(allocation.id)}`,
      );
      setType('');
      queryClient.invalidateQueries(['all-employees']);
      queryClient.invalidateQueries(['team-structure']);
      queryClient.invalidateQueries(['reallocation', allocation.employeeId]);

      toast.success(response.data.message, {
        theme: 'colored',
        toastId: 'success',
      });
    } catch (e) {
      toast.error('Não foi possível deletar a alocação', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    } finally {
      setIsConfirmationModalOpen(false);
    }
  };

  const handleEdit = async () => {
    setType('edit');
    setEditData({
      date: removeTimeZone(new Date(allocation.startDate)),
      teamStructureId: allocation.teamStructureId,
      teamId: allocation.teamId,
      justification: allocation.justification || '',
      allocationId: allocation.id,
    });
  };

  const handleEditDeallocation = async () => {
    setType('deallocate');
    if (allocation.endDate) {
      setDeallocationEditData({
        allocationId: allocation.id,
        date: removeTimeZone(new Date(allocation.endDate)),
        justification: allocation.justification || '',
      });
    }
  };

  const handleCloseDeleteModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleDeleteDeallocation = async () => {
    try {
      const response = await api.delete(
        `/team-employee/delete-desallocation?employeeTeamHistoryId=${Number(allocation.id)}`,
      );
      setIsConfirmationModalOpen(false);
      setType('');
      queryClient.invalidateQueries(['all-employees']);
      queryClient.invalidateQueries(['team-structure']);
      queryClient.invalidateQueries(['reallocation', allocation.employeeId]);

      toast.success(response.data.message, {
        theme: 'colored',
        toastId: 'success',
      });
    } catch (e) {
      toast.error('Não foi possível deletar a desalocação', {
        theme: 'colored',
        toastId: 'error',
      });
      setIsConfirmationModalOpen(false);
      throw e;
    }
  };

  const handleConfirmAction = () => {
    if (deleteType === 'deallocation') handleDeleteDeallocation();
    else handleDelete();
  };

  const editOptions: AllocationMenuOptions[] = [
    {
      name: 'Editar Realocação',
      onClick: () => {
        handleEdit();
      },
      disabled: !allocation.canBeEditable || !!allocation.canEditDesallocation,
    },
    {
      name: 'Editar Desalocação',
      onClick: () => {
        handleEditDeallocation();
      },
      disabled: !allocation.canEditDesallocation,
    },
  ];

  const deleteOptions: AllocationMenuOptions[] = [
    {
      name: 'Remover Realocação',
      onClick: () => {
        setDeleteType('reallocation');
        setIsConfirmationModalOpen(true);
      },
      disabled: !allocation.canBeEditable || !!allocation.canEditDesallocation,
    },
    {
      name: 'Remover Desalocação',
      onClick: () => {
        setDeleteType('deallocation');
        setIsConfirmationModalOpen(true);
      },
      disabled: !allocation.canEditDesallocation,
    },
  ];

  return (
    <div className="flex h-[35px] items-center justify-center">
      <div>
        <AllocationMenu options={editOptions} editButton={true} />
      </div>
      <div className="pl-2">
        <AllocationMenu options={deleteOptions} deleteButton={true} />
      </div>
      <ModalConfirmation
        title={''}
        description={
          deleteType === 'deallocation'
            ? `Tem certeza que deseja cancelar a desalocação da turma ${allocation.team}?`
            : 'Tem certeza que deseja excluir essa alteração?'
        }
        confirmAction={handleConfirmAction}
        isOpen={isConfirmationModalOpen}
        onClose={handleCloseDeleteModal}
        discartAction={handleCloseDeleteModal}
      />
    </div>
  );
};

export default AllocationButtons;
