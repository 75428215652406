import { atom } from 'recoil';

import { DesallocationForm } from '@/constants/AllocationConstants';

export const DeallocationEditData = atom<DesallocationForm>({
  key: 'allocationData',
  default: {
    date: new Date(),
    justification: '',
  },
});
