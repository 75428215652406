import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import React, {
  FocusEventHandler,
  KeyboardEventHandler,
  useState,
} from 'react';

import EditButton from './EditButton';
import Input from './Input';
import TableCellText from './TableCellText';

interface Props {
  title?: string;
  value: number | string;
  disabled?: boolean;
  setState: (value: any) => void;
  onKeyDown: KeyboardEventHandler;
  onBlur: FocusEventHandler;
}

const EditableTableInputText = ({
  title,
  value,
  disabled,
  setState,
  onKeyDown,
  onBlur,
}: Props) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  return (
    <>
      <div className="h-inherit flex items-center">
        <TableCellText
          text={value.toString()}
          align="start"
          textAlign="text-left"
        />
        <EditButton onClick={handleClick} disabled={!!disabled} />
      </div>
      <BasePopup open={Boolean(anchor)} anchor={anchor}>
        <div className=" rounded bg-white">
          <Input
            label={title}
            required={false}
            title={title}
            value={value}
            onChange={(e) => {
              setState(e.target.value);
            }}
            onKeyDown={onKeyDown}
            onBlur={(e) => {
              onBlur(e);
              setAnchor(null);
            }}
          />
        </div>
      </BasePopup>
    </>
  );
};

export default EditableTableInputText;
