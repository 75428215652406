import { Dialog, Transition } from '@headlessui/react';

import { CombinedJobTitle, NewJobTitle } from '@/types/NewJobTitle';

import {
  formatddMMyyyy,
  removeTimeZoneWithoutTimezone,
} from '../../utils/formatDate';
import CloseButton from '../atoms/CloseButton';
import SubtitleText from '../atoms/SubtitleText';
import InfoSection from '../molecules/InfoSection';

interface Props {
  modalIsOpen: boolean;
  handleClose: () => void;
  newJobTitle: NewJobTitle;
}

export default function RoleModal({
  newJobTitle,
  modalIsOpen,
  handleClose,
}: Props) {
  function convertToCombinedJobTitle(
    newJobTitle: NewJobTitle,
  ): CombinedJobTitle {
    return {
      id: newJobTitle.id,
      issueId: newJobTitle.generalInformationJobTitle.issueId,
      name: newJobTitle.generalInformationJobTitle.name,
      objective: newJobTitle.objective,
      version: newJobTitle.generalInformationJobTitle.version,
      seniorityLevelName: newJobTitle.seniorityLevel.name,
      responsibilities: newJobTitle.responsibilities,
      experience: newJobTitle.experience,
      skillsAndCompetencies: newJobTitle.skillsAndCompetencies,
      updated_at: newJobTitle.seniorityLevel.updated_at,
      active: newJobTitle.active,
      direction:
        newJobTitle.generalInformationJobTitle?.GeneralInformationJobTitleDirection?.map(
          (el) => el.Direction.name,
        ),
      management:
        newJobTitle.generalInformationJobTitle?.GeneralInformationJobTitleManagement?.map(
          (el) => el.Management.name,
        ),
      workStations: newJobTitle.generalInformationJobTitle?.ghe?.map(
        (el) => el.workStation.name,
      ),
      GHEPerWorkStation: newJobTitle.generalInformationJobTitle?.ghe?.map(
        (el) => ({
          ghe: el.ghe,
          workStation: el.workStation.name,
        }),
      ),
      educationalRequirements: newJobTitle.SelectedRequirements.filter(
        (selectedRequirement) =>
          selectedRequirement.Requirements.requirementType ===
            'Requisitos Educacionais' && selectedRequirement.required,
      ).map(
        (selectedRequirement) => selectedRequirement.Requirements.requirement,
      ),
      professionalRecords: newJobTitle.SelectedRequirements.filter(
        (selectedRequirement) =>
          selectedRequirement.Requirements.requirementType ===
            'Registros Profissionais' && selectedRequirement.required,
      ).map(
        (selectedRequirement) => selectedRequirement.Requirements.requirement,
      ),
      otherRequirements: newJobTitle.SelectedRequirements.filter(
        (selectedRequirement) =>
          selectedRequirement.Requirements.requirementType ===
            'Outros Requisitos' && selectedRequirement.required,
      ).map(
        (selectedRequirement) => selectedRequirement.Requirements.requirement,
      ),
      educationalRequirementsOptional: newJobTitle.SelectedRequirements.filter(
        (selectedRequirement) =>
          selectedRequirement.Requirements.requirementType ===
            'Requisitos Educacionais' && !selectedRequirement.required,
      ).map(
        (selectedRequirement) => selectedRequirement.Requirements.requirement,
      ),
      professionalRecordsOptional: newJobTitle.SelectedRequirements.filter(
        (selectedRequirement) =>
          selectedRequirement.Requirements.requirementType ===
            'Registros Profissionais' && !selectedRequirement.required,
      ).map(
        (selectedRequirement) => selectedRequirement.Requirements.requirement,
      ),
      otherRequirementsOptional: newJobTitle.SelectedRequirements.filter(
        (selectedRequirement) =>
          selectedRequirement.Requirements.requirementType ===
            'Outros Requisitos' && !selectedRequirement.required,
      ).map(
        (selectedRequirement) => selectedRequirement.Requirements.requirement,
      ),
      creator: newJobTitle.creator,
      revisor: newJobTitle.revisor,
      firstValidator: newJobTitle.firstValidator,
      secondValidator: newJobTitle.secondValidator,
    };
  }

  const role = convertToCombinedJobTitle(newJobTitle);

  const sections = [
    {
      sectionsPerRow: 2,
      content: [
        { title: 'Título do Cargo', content: role.name },
        { title: 'Nível', content: role.seniorityLevelName },
      ],
    },
    {
      sectionsPerRow: 2,
      content: [
        { title: 'Diretoria', content: role.direction.join('; ') },
        { title: 'Gerência', content: role.management.join('; ') },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'GHE por Local de Trabalho',
          content: (
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="w-1/2 border border-gray-300 px-4 py-2 text-center">
                    Local de Trabalho
                  </th>
                  <th className="w-1/2 border border-gray-300 px-4 py-2 text-center">
                    GHE
                  </th>
                </tr>
              </thead>
              <tbody>
                {role.GHEPerWorkStation.map((ghePerWorkStation, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 px-4 py-2 text-center">
                      {ghePerWorkStation.workStation}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-center">
                      {ghePerWorkStation.ghe}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ),
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Objetivo do Cargo',
          content: role.objective,
          contentClass:
            'text-justify text-black font-normal text-md p-4 whitespace-pre-wrap',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Funções e Responsabilidades',
          content: role.responsibilities,
          contentClass:
            'text-justify text-black font-normal text-md p-4 whitespace-pre-wrap',
        },
      ],
    },
    {
      content: [
        {
          title: 'Requisitos',
          subtitle: 'Requisitos Educacionais Obrigatórios',
          content: role.educationalRequirements.length
            ? role.educationalRequirements.join(' - ')
            : null,
          baseContentClass: 'text-black font-normal text-md pl-6',
          containerSubtitleClassname: 'border-2 border-gray-300 rounded mx-4',
          subtitleClassname: 'pl-2 text-black text-md',
        },
        {
          subtitle: 'Requisitos Educacionais Desejáveis',
          content: role.educationalRequirementsOptional.length
            ? role.educationalRequirementsOptional.join(' - ')
            : null,
          baseContentClass: 'text-black font-normal text-md pl-6',
          subtitleClassname: 'pl-2 text-black text-md',
        },
        {
          subtitle: 'Registros Profissionais Obrigatórios',
          content: role.professionalRecords.length
            ? role.professionalRecords.join(' - ')
            : null,
          baseContentClass: 'text-black font-normal text-md pl-6',
          subtitleClassname: 'pl-2 text-black text-md',
        },
        {
          subtitle: 'Registros Profissionais Desejáveis',
          content: role.professionalRecordsOptional.length
            ? role.professionalRecordsOptional.join(' - ')
            : null,
          baseContentClass: 'text-black font-normal text-md pl-6',
          subtitleClassname: 'pl-2 text-black text-md',
        },
        {
          subtitle: 'Outros Requisitos Obrigatórios',
          content: role.otherRequirements.length
            ? role.otherRequirements.join(' - ')
            : null,
          baseContentClass: 'text-black font-normal text-md pl-6',
          subtitleClassname: 'pl-2 text-black text-md',
        },
        {
          subtitle: 'Outros Requisitos Desejáveis',
          content: role.otherRequirementsOptional.length
            ? role.otherRequirementsOptional.join(' - ')
            : null,
          baseContentClass: 'text-black font-normal text-md pl-6',
          subtitleClassname: 'pl-2 text-black text-md',
        },
      ].filter(
        (field) => field.content !== null && field.content !== undefined,
      ),
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Experiências',
          content: role.experience,
          contentClass:
            'text-justify text-black font-normal text-md p-4 whitespace-pre-wrap',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Habilidades e Competências',
          content: role.skillsAndCompetencies,
          contentClass:
            'text-justify text-black font-normal text-md p-4 whitespace-pre-wrap',
        },
      ],
    },
    {
      sectionsPerRow: 2,
      content: [
        { title: 'Versão', content: role.version },
        {
          title: 'Última Atualização',
          content: newJobTitle.seniorityLevel.updated_at
            ? formatddMMyyyy(
                removeTimeZoneWithoutTimezone(
                  newJobTitle.seniorityLevel.updated_at.toString(),
                ),
              )
            : null,
        },
        { title: 'Situação', content: role.active ? 'Ativo' : 'Inativo' },
        {
          title: 'Data de Criação',
          content: newJobTitle.seniorityLevel.created_at
            ? formatddMMyyyy(
                removeTimeZoneWithoutTimezone(
                  newJobTitle.seniorityLevel.created_at.toString(),
                ),
              )
            : null,
        },
      ],
    },
    {
      sectionsPerRow: 2,
      content: [
        { title: 'Criador', content: role.creator },
        {
          title: 'Revisor',
          content: role.revisor,
        },
        {
          title: 'Aprovador',
          content: role.secondValidator,
        },
      ],
    },
  ].filter((section) =>
    section.content.some(
      (field) => field.content !== null && field.content !== undefined,
    ),
  );

  const baseContainerClass = 'bg-white p-1 border-r rounded pb-0';
  const baseHeaderClass =
    'bg-[#EDF0FA] rounded flex items-center p-1 pl-4 mb-1 text-[#193CB9] text-xl font-semibold';
  const baseContentClass = 'text-black font-normal text-md p-4';
  const subtitleClassname = 'pl-6 text-lg border';
  const containerSubtitleClassname = 'border-2 border-gray-300 rounded mx-4';

  return (
    <Transition appear show={modalIsOpen}>
      <Dialog className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            className={'w-1/2'}
          >
            <Dialog.Panel className="w-full flex-col justify-between rounded-lg bg-white">
              <div className="w-full flex-col justify-between pl-5 pt-5">
                <div className="flex h-auto items-center justify-between">
                  <div className="flex justify-between">
                    <SubtitleText
                      className="text-[26px]"
                      subtitle={'Descrição do Cargo'}
                    />
                  </div>
                  <CloseButton onClick={handleClose} className="mt-[-1rem]" />
                </div>
                <div className="custom-scrollbar mt-5 max-h-[85vh] w-full overflow-auto overflow-y-scroll border border-[#EDF0FA] pb-5 text-[#555555]">
                  {sections.map((section, index) => (
                    <div
                      key={index}
                      className={`grid ${section.sectionsPerRow === 2 ? 'grid-cols-2' : 'grid-cols-1'}`}
                    >
                      {section.content.map((content: any, idx) => (
                        <InfoSection
                          key={idx}
                          title={content?.title ?? ''}
                          subtitle={content?.subtitle ?? ''}
                          content={content.content}
                          minHeight={'5rem'}
                          containerClass={baseContainerClass}
                          headerClass={baseHeaderClass}
                          contentClass={
                            content?.contentClass ?? baseContentClass
                          }
                          subtitleClassname={
                            content?.subtitleClassname ?? subtitleClassname
                          }
                          containerSubtitleClassname={
                            content?.containerSubtitleClassname ??
                            containerSubtitleClassname
                          }
                        />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
