import UploadFileIcon from '@mui/icons-material/UploadFile';
import React, { useCallback, useState } from 'react';

import LightTooltip from './LightTooltip';

interface FileUploadComponentProps {
  onFilesSelected: (files: FileList | null) => void;
  rowId: string;
  disabled?: boolean;
  addMargin?: boolean;
}

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
  onFilesSelected,
  rowId,
  disabled,
  addMargin = true,
}) => {
  const [dragging, setDragging] = useState(false);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        onFilesSelected(e.dataTransfer.files);
        e.dataTransfer.clearData();
      }
    },
    [onFilesSelected],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        onFilesSelected(event.target.files);
      }
    },
    [onFilesSelected],
  );

  return (
    <LightTooltip title="Arraste e solte alguns arquivos aqui ou clique para selecionar arquivos">
      <div
        className={`${addMargin && 'mr-3'} cursor-pointer ${dragging ? 'border-blue-500 bg-blue-100' : 'border-gray-300'}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          multiple
          disabled={disabled}
          onChange={handleChange}
          className="hidden"
          id={`file-upload-${rowId}`}
        />
        <label
          htmlFor={`file-upload-${rowId}`}
          className="flex h-full flex-col items-center justify-center text-center"
        >
          <UploadFileIcon sx={{ color: '#1E40AF' }} />
        </label>
      </div>
    </LightTooltip>
  );
};

export default FileUploadComponent;
