import { forwardRef, useEffect, useState } from 'react';

import { getJobInfo } from '@/constants/SectionsRoleExtraction';
import { NewJobTitle } from '@/types/NewJobTitle';

import InfoLayout from './InfoLayout';

interface SectionContent {
  title?: string;
  subtitle?: string;
  content: any;
  minHeight: string;
  containerClass: string;
  headerClass: string;
  contentClass: string;
}

interface Section {
  sectionsPerRow: 1 | 2;
  content: SectionContent[];
}

interface PrintJobTitlePDFProps {
  newJobTitle: NewJobTitle;
}

const PrintJobTitlePDF = forwardRef<HTMLDivElement, PrintJobTitlePDFProps>(
  ({ newJobTitle }, ref) => {
    const [sectionsFirstPage, setSectionsFirstPage] = useState<Section[]>([]);
    const [sectionsSecondPage, setSectionsSecondPage] = useState<Section[]>([]);
    const [sectionsThirdPage, setSectionsThirdPage] = useState<Section[]>([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getJobInfo(newJobTitle);

          setSectionsFirstPage(data.RoleExtractionFirstPage);
          setSectionsSecondPage(data.RoleExtractionSecondPage);
          setSectionsThirdPage(data.RoleExtractionThirdPage);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }, [newJobTitle]);

    return (
      <div ref={ref} style={{ width: '100%' }} className="print-roles portrait">
        <InfoLayout
          title="Descrição do Cargo"
          headerClass="bg-[#EDF0FA] text-gray-700 font-semibold p-4 pl-4 mb-2 h-[10vh] flex items-center justify-between rounded text-[40px] w-full"
          sections={sectionsFirstPage}
        />
        <InfoLayout sections={sectionsSecondPage} />
        <InfoLayout sections={sectionsThirdPage} />
      </div>
    );
  },
);

PrintJobTitlePDF.displayName = 'PrintJobTitlePDF';
export default PrintJobTitlePDF;
