import { z } from 'zod';

const ReallocationTeamSchema = z.object({
  id: z.number(),
  name: z.string(),
});

const ReallocationTeamStructureSchema = z.object({
  id: z.number(),
  name: z.string(),
  teams: z.array(ReallocationTeamSchema),
});

const AllocationHistorySchema = z.object({
  id: z.number(),
  employeeId: z.number(),
  teamStructureId: z.number(),
  teamId: z.number(),
  management: z.string(),
  team: z.string(),
  teamStructure: z.string(),
  startDate: z.string(),
  endDate: z.string().nullable(),
  justification: z.string().nullable(),
  current: z.boolean(),
  canBeEditable: z.boolean().optional(),
  canEditDesallocation: z.boolean().optional(),
});

export const ReallocationSchema = z.object({
  name: z.string(),
  employeeNumber: z.string(),
  vacationReliefWorker: z.boolean(),
  allocationHistories: z.array(AllocationHistorySchema),
  teamStructures: z.array(ReallocationTeamStructureSchema),
});

export type Reallocation = z.infer<typeof ReallocationSchema>;
export type AllocationHistory = z.infer<typeof AllocationHistorySchema>;
export type ReallocationTeam = z.infer<typeof ReallocationTeamSchema>;
export type ReallocationStructure = z.infer<
  typeof ReallocationTeamStructureSchema
>;

export const DesallocationFormSchema = z.object({
  allocationId: z.number().optional(),
  date: z.coerce.date(),
  justification: z.string().min(1),
});

export type DesallocationForm = z.infer<typeof DesallocationFormSchema>;

export const FormSchema = z.object({
  allocationId: z.number().optional(),
  teamStructureId: z.number().min(1),
  teamId: z.number().min(1),
  date: z.coerce.date(),
  justification: z.string().trim().min(1),
});

export type AllocationFormRequest = z.infer<typeof FormSchema>;
