import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';

import { AllocationRequestHistory } from '@/constants/AllocationRequest';
import api from '@/services/apiSgft';
import { AllocationRequestEditData } from '@/state/AllocationRequestEditData.atom';
import { AllocationTypeAtom } from '@/state/AllocationType.atom';
import { removeTimeZone } from '@/utils/formatDate';

import AllocationMenu from '../atoms/AllocationMenu';
import ModalConfirmation from './ConfirmationModal';

interface Props {
  allocation: AllocationRequestHistory;
}

const AllocationRequestButtons = ({ allocation }: Props) => {
  const queryClient = useQueryClient();
  const setType = useSetRecoilState(AllocationTypeAtom);
  const setEditData = useSetRecoilState(AllocationRequestEditData);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleDelete = async () => {
    try {
      await api.delete(
        `/team-employee/delete-allocation-request/${Number(allocation.id)}`,
      );
      setType('');
      queryClient.invalidateQueries([
        'allocation-requests',
        allocation.employeeId,
      ]);
    } catch (e) {
      toast.error('Não foi possível deletar a solicitação de realocação', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    } finally {
      setIsConfirmationModalOpen(false);
    }
  };

  const handleEdit = async () => {
    setType('edit');
    setEditData({
      date: removeTimeZone(new Date(allocation.requestDate)),
      teamStructureId: allocation.teamStructureId,
      teamId: allocation.teamId,
      allocationRequestId: allocation.id,
      justification: allocation.justification,
      motive: allocation.motive,
      files: allocation.files,
    });
  };

  const handleCloseDeleteModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleConfirmAction = () => {
    handleDelete();
  };

  const editOptions = [
    {
      name: 'Editar Solicitação',
      onClick: () => {
        handleEdit();
      },
      disabled: false,
    },
  ];

  const deleteOptions = [
    {
      name: 'Remover Solicitação',
      onClick: () => {
        setIsConfirmationModalOpen(true);
      },
      disabled: false,
    },
  ];

  return (
    <div className="flex h-[35px] items-center justify-center">
      <div>
        <AllocationMenu options={editOptions} editButton={true} />
      </div>
      <div className="pl-2">
        <AllocationMenu options={deleteOptions} deleteButton={true} />
      </div>
      <ModalConfirmation
        title={''}
        description="Tem certeza que deseja excluir essa solicitação?"
        confirmAction={handleConfirmAction}
        isOpen={isConfirmationModalOpen}
        onClose={handleCloseDeleteModal}
        discartAction={handleCloseDeleteModal}
      />
    </div>
  );
};

export default AllocationRequestButtons;
