/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';

import api from '@/services/apiSgft';
import { NewJobTitleAtom } from '@/state/NewJobTitle.atom';

import { Filter, Option } from '../../types/Filter';
import { RoleFilterOptionsSchema, RolesFiltersOptions } from '../RoleConstants';

const fetchFilter = async (
  field: keyof RolesFiltersOptions,
  search: string,
  atom?: NewJobTitleAtom,
) => {
  try {
    const response = await api.get('new-job-title/filters', {
      params: {
        title: atom?.title,
        level: atom?.level,
        direction: atom?.direction,
        management: atom?.management,
        workStation: atom?.workStation,
        status: atom?.status,
        requirementType: atom?.requirementType,
        educationalRequirements: atom?.educationalRequirements,
        professionalRecords: atom?.professionalRecords,
        otherRequirements: atom?.otherRequirements,
      },
    });
    const parsedFilters = RoleFilterOptionsSchema.parse(response.data);
    const fieldOptions = parsedFilters[field] || [];
    return fieldOptions;
  } catch (e) {
    console.log(e);
    toast.error('Erro ao carregar os dados', {
      theme: 'colored',
      toastId: 'error',
    });
    throw e;
  }
};

export const filters: Filter[] = [
  {
    key: 'levels',
    name: 'Nível',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: NewJobTitleAtom) =>
      fetchFilter('levels', search, atom),
    isDisabled: (_: NewJobTitleAtom) => false,
  },
  {
    key: 'directions',
    name: 'Diretoria',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: NewJobTitleAtom) =>
      fetchFilter('directions', search, atom),
    isDisabled: (_: NewJobTitleAtom) => false,
  },
  {
    key: 'managements',
    name: 'Gerência',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: NewJobTitleAtom) =>
      fetchFilter('managements', search, atom),
    isDisabled: (_: NewJobTitleAtom) => false,
  },
  {
    key: 'workStations',
    name: 'Local de Trabalho',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: NewJobTitleAtom) =>
      fetchFilter('workStations', search, atom),
    isDisabled: (_: NewJobTitleAtom) => false,
  },
  {
    key: 'statuses',
    name: 'Situação',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: NewJobTitleAtom) =>
      fetchFilter('statuses', search, atom),
    isDisabled: (_: NewJobTitleAtom) => false,
  },
  {
    key: 'requirementTypes',
    name: 'Tipo de Requisito',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: NewJobTitleAtom) =>
      fetchFilter('requirementTypes', search, atom),
    isDisabled: (_: NewJobTitleAtom) => false,
  },
  {
    key: 'educationalRequirements',
    name: 'Requisitos Educacionais',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: NewJobTitleAtom) =>
      fetchFilter('educationalRequirements', search, atom),
    isDisabled: (_: NewJobTitleAtom) => false,
  },
  {
    key: 'professionalRecords',
    name: 'Registros Profissionais',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: NewJobTitleAtom) =>
      fetchFilter('professionalRecords', search, atom),
    isDisabled: (_: NewJobTitleAtom) => false,
  },
  {
    key: 'otherRequirements',
    name: 'Outros Requisitos',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: NewJobTitleAtom) =>
      fetchFilter('otherRequirements', search, atom),
    isDisabled: (_: NewJobTitleAtom) => false,
  },
];
