import { z } from 'zod';

import { Values } from '@/components/atoms/ControlledSimpleSelect';
import { EvidenceFile } from '@/types/EvidenceFile';

export enum AllocationMotive {
  OrigemCarreiras = 'Origem Carreiras',
  Transferencia = 'Transferência',
  CoberturaDeFerias = 'Cobertura de férias',
  DeterminacaoDaEmpresa = 'Determinação da empresa',
}

export const allocationMotiveValues: Values[] = Object.values(
  AllocationMotive,
).map((value) => ({
  label: value,
  value,
}));

const filesSchema = z.custom<EvidenceFile[]>();

export enum AllocationRequestStatus {
  APPROVED = 'Aprovado',
  OPEN = 'Aberto',
  REFUSED = 'Recusado',
  PENDING = 'Pendente',
}

export const FormSchema = z.object({
  allocationRequestId: z.number().optional(),
  teamStructureId: z.number().min(1),
  teamId: z.number().min(1),
  date: z.coerce.date(),
  motive: z.enum([
    AllocationMotive.OrigemCarreiras,
    AllocationMotive.Transferencia,
    AllocationMotive.CoberturaDeFerias,
    AllocationMotive.DeterminacaoDaEmpresa,
  ]),
  justification: z.string().trim().min(1),
  evidence: z.any().optional(),
  files: filesSchema.nullable(),
});

export const formSchemaError = z.object({
  evidenceError: z.object({
    message: z.string(),
  }),
});

export interface AllocationRequest {
  employeeId: number | undefined;
  teamId: number;
  date: Date | undefined;
  motive: string;
  justification: string;
}

export const allocationRequestMessageSchema = z.object({
  message: z.string(),
});

export const allocationRequestResponseSchema = z.object({
  id: z.number().transform((id) => id.toString()),
});

export const allocationRequestApproveResponseSchema = z.object({
  message: z.string(),
});

export type AllocationFormRequest = z.infer<typeof FormSchema>;

export const AllocationRequestHistorySchema = z.object({
  id: z.number(),
  employeeId: z.number(),
  requester: z.string().email(),
  teamStructureId: z.number(),
  teamId: z.number(),
  management: z.string(),
  team: z.string(),
  teamStructure: z.string(),
  requestDate: z.string(),
  motive: z.enum([
    AllocationMotive.CoberturaDeFerias,
    AllocationMotive.DeterminacaoDaEmpresa,
    AllocationMotive.OrigemCarreiras,
    AllocationMotive.Transferencia,
  ]),
  justification: z.string(),
  files: filesSchema.nullable(),
});

export const AllocationRequestHistoryListSchema = z.array(
  AllocationRequestHistorySchema,
);

export type AllocationRequestHistory = z.infer<
  typeof AllocationRequestHistorySchema
>;

export type AllocationRequestHistoryList = z.infer<
  typeof AllocationRequestHistoryListSchema
>;
