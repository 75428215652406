import { atom } from 'recoil';

export interface RequirementControlAtom extends Record<string, string[]> {
  employee: string[];
  role: string[];
  management: string[];
  areaCoordinator: string[];
  pole: string[];
  workstation: string[];
  status: string[];
  situation: string[];
  requirementType: string[];
  requirementClass: string[];
  requirement: string[];
}

export const requirementControlAtom = atom<RequirementControlAtom>({
  key: 'requirementControl',
  default: {
    employee: [],
    role: [],
    management: [],
    areaCoordinator: [],
    pole: [],
    workstation: [],
    status: [],
    situation: [],
    requirementType: [],
    requirementClass: [],
    requirement: [],
  },
});
