import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  DesallocationForm,
  DesallocationFormSchema,
} from '@/constants/AllocationConstants';
import api from '@/services/apiSgft';
import { DeallocationEditData } from '@/state/AllocationDataType.atom';
import { AllocationTypeAtom } from '@/state/AllocationType.atom';

import { Button } from '../atoms/Button';
import ControlledCalendar from '../atoms/ControlledCalendar';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import ModalConfirmation from './ConfirmationModal';

interface DesallocateFormProps {
  employeeId: number | undefined;
  defaultValues?: DesallocationForm;
}

export const DesallocateForm = ({
  employeeId,
  defaultValues,
}: DesallocateFormProps) => {
  const setType = useSetRecoilState(AllocationTypeAtom);
  const resetEditData = useResetRecoilState(DeallocationEditData);
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [formData, setFormData] = useState<DesallocationForm | null>(null);
  const queryClient = useQueryClient();

  const form = useForm<DesallocationForm>({
    resolver: zodResolver(DesallocationFormSchema),
    defaultValues,
  });

  function onSubmit(data: DesallocationForm) {
    setIsModalConfirmationOpen(true);
    setFormData(data);
  }

  async function handleSaveData() {
    if (formData) {
      try {
        const response = await api.post(
          `team-employee/desallocate/${employeeId}`,
          {
            endDate: formData.date,
            justification: formData.justification,
          },
        );
        toast.success(response.data.message, {
          theme: 'colored',
          toastId: 'success',
        });

        queryClient.invalidateQueries(['all-employees']);
        queryClient.invalidateQueries(['team-structure']);
        queryClient.invalidateQueries(['reallocation', employeeId]);

        reset();
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.response?.data.message, {
            theme: 'colored',
            toastId: 'error',
          });
          return;
        }
        toast.error(
          'Sua solicitação não foi processada. Tente novamente mais tarde!',
          {
            theme: 'colored',
            toastId: 'error',
          },
        );
      } finally {
        setIsModalConfirmationOpen(false);
      }
    }
  }

  const allocationDateWatch = form.watch('date');
  const isAbleToSave = form.formState.isValid && !!allocationDateWatch;

  function onCancel() {
    reset();
  }

  function reset() {
    form.reset();
    setType('');
    resetEditData();
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-3 flex w-full justify-between gap-4">
          <div className="flex-1">
            <UncontrolledInputText
              title="Justificativa"
              fullWidth
              fieldError={form.formState.errors.justification}
              errorMessage="A justificativa é obrigatória*"
              {...form.register('justification')}
            />
          </div>

          <ControlledCalendar
            title="Data da desalocação"
            required
            name="date"
            width="10rem"
            control={form.control}
            fieldError={form.formState.errors.date}
            errorMessage="A data da desalocação é obrigatória*"
            minDate={new Date()}
          />
        </div>
        <div className="flex justify-between p-5">
          <Button
            type="button"
            variant="outline"
            className="min-w-40 font-semibold ring-1"
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button type="submit" disabled={!isAbleToSave} className="min-w-40">
            Salvar
          </Button>
        </div>
        {isModalConfirmationOpen && (
          <ModalConfirmation
            title=""
            description="Tem certeza que deseja desalocar esse colaborador?"
            confirmAction={handleSaveData}
            isOpen={isModalConfirmationOpen}
            onClose={() => setIsModalConfirmationOpen(false)}
            discartAction={() => setIsModalConfirmationOpen(false)}
          />
        )}
      </form>
    </FormProvider>
  );
};
