/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table';
import { FaChevronDown } from 'react-icons/fa';

import TableData from '@/components/atoms/TableData';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

import TableCellText from '../../components/atoms/TableCellText';

export const columns: (
  isOpen: boolean,
  handleToggleCard: () => void,
) => Array<
  ColumnDef<{
    id: number;
    name: string;
    employeeNumber: string;
    role: string;
    workStation: string;
    pole: string;
    management: string;
    areaCoordinator: string;
    direction: string;
    seniorityLevel: string;
    requirements: number;
    situation: string;
  }>
> = (isOpen: boolean, handleToggleCard: () => void) => [
  {
    accessorKey: 'chevron',
    accessorFn: (row) => row.name,
    header: 'Nome',
    meta: {
      headerClassName: 'bg-white text-start px-4',
      // stickyClassName:
      //   'sticky left-0 z-50 align-center flex items-center justify-center h-[40px]',
    },
    // meta: {
    //   headerClassName: 'sticky left-[-0.1rem] z-10 bg-white',
    //   stickyClassName: 'sticky left-[0.4rem] z-10 bg-inherit',
    // },
    cell: () => {
      return (
        <button
          onClick={handleToggleCard}
          className="sticky left-4 z-[100] flex w-[40px] items-center justify-center bg-white pt-[2px]"
        >
          <FaChevronDown
            className={`fill-primary transition-all ${isOpen && 'rotate-180'}`}
          />
        </button>
      );
    },
  },
  {
    accessorKey: 'name',
    accessorFn: (row) => row.name,
    header: 'Nome',
    meta: {
      headerClassName: 'bg-white text-start',
      // stickyClassName: 'sticky left-[2.6rem] z-50',
    },
    cell: (props) => {
      return (
        <div className={`flex w-full items-center justify-start`}>
          <TableCellText
            text={'Nome:'}
            align="start"
            textAlign="text-center"
            className="font-bold uppercase text-primary"
            width="5rem"
          />
          <TableData
            tableValue={getFirstAndLastName(props.getValue() as string)}
            className="flex h-[32px] w-32 items-center justify-start whitespace-nowrap text-[11px] font-semibold"
            title={props.getValue() as string}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'employeeNumber',
    accessorFn: (row) => row.employeeNumber,
    header: 'Chapa',
    meta: {
      headerClassName: 'bg-white text-start',
      // stickyClassName: 'sticky left-[15.7rem] z-50',
    },
    cell: (props) => {
      return (
        <div className={`flex items-center justify-start`}>
          <TableCellText
            text={'Chapa:'}
            align="center"
            textAlign="text-center"
            className="font-bold uppercase text-primary"
            width="4rem"
          />
          <TableCellText
            text={props.getValue() as string}
            align="start"
            textAlign="text-left"
            width="5rem"
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'pole',
    accessorFn: (row) => row.pole,
    header: 'Polo',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="ml-5 mr-5 flex items-center justify-start">
        <TableCellText
          text={'Polo:'}
          align="center"
          textAlign="text-center"
          className="font-bold uppercase text-primary"
          width="4rem"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          textAlign="text-left"
          className="uppercase"
          width="5rem"
        />
      </div>
    ),
  },
  {
    accessorKey: 'direction',
    accessorFn: (row) => row.direction,
    header: 'Diretoria',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      return (
        <div className="mr-5 flex items-start">
          <TableCellText
            text={'Diretoria:'}
            align="center"
            textAlign="text-center"
            className="font-bold uppercase text-primary"
            width="5rem"
          />
          <TableCellText
            text={
              (props.getValue() as string) &&
              (props.getValue() as string) !== '-'
                ? (props.getValue() as string)
                : 'N/A'
            }
            align="start"
            width="12rem"
            textAlign="text-left"
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'management',
    accessorFn: (row) => row.management,
    header: 'Gerência',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex items-start">
        <TableCellText
          text={'Gerência:'}
          align="center"
          textAlign="text-center"
          className="font-bold uppercase text-primary"
          width="5rem"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          width="12rem"
          textAlign="text-left"
        />
      </div>
    ),
  },
  {
    accessorKey: 'areaCoordinator',
    accessorFn: (row) => row.areaCoordinator,
    header: 'Coordenação',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex items-start">
        <TableCellText
          text={'Coordenação:'}
          align="center"
          textAlign="text-center"
          className="font-bold uppercase text-primary"
          width="7rem"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          textAlign="text-left"
          width="9rem"
        />
      </div>
    ),
  },
  {
    accessorKey: 'workStation',
    accessorFn: (row) => row.workStation,
    header: 'Local de Trabalho',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex items-start">
        <TableCellText
          text={'Local de Trabalho:'}
          align="center"
          textAlign="text-center"
          className="font-bold uppercase text-primary"
          width="9rem"
        />
        <TableCellText
          text={props.getValue() ? (props.getValue() as string) : 'N/A'}
          align="start"
          textAlign="text-left"
          width="8rem"
        />
      </div>
    ),
  },
  {
    accessorKey: 'role',
    accessorFn: (row) => row.role,
    header: 'Função',
    meta: {
      headerClassName: 'text-center',
    },
    cell: (props) => (
      <div className="flex items-start">
        <TableCellText
          text={'Função:'}
          align="center"
          textAlign="text-center"
          className="font-bold uppercase text-primary"
          width="4rem"
        />
        <TableCellText
          text={props.getValue() as string}
          align="start"
          width="6rem"
          textAlign="text-left"
        />
      </div>
    ),
  },
  {
    accessorKey: 'seniorityLevel',
    accessorFn: (row) => row.seniorityLevel,
    header: 'Nível',
    meta: {
      headerClassName: '!text-center',
    },
    cell: (props) => (
      <div className="flex items-center">
        <TableCellText
          text={'Nível:'}
          align="center"
          textAlign="text-center"
          className="font-bold uppercase text-primary"
          width="4rem"
        />
        <TableCellText
          text={props.getValue() as string}
          align="center"
          width="4rem"
          textAlign="text-left"
        />
      </div>
    ),
  },
  {
    accessorKey: 'requirements',
    accessorFn: (row) => row.requirements,
    header: 'Total de requisitos',
    meta: {
      headerClassName: '!text-center',
    },
    cell: (props) => (
      <div className="m-auto flex items-center justify-start ">
        <TableCellText
          text={'Total de requisitos:'}
          align="center"
          textAlign="text-center"
          className="font-bold uppercase text-primary"
          width="11rem"
        />
        <TableCellText
          text={props.getValue() as string}
          align="start"
          width="3rem"
          textAlign="text-center"
          className="!w-[3rem] bg-[#D1E6FF] font-bold text-primary"
        />
      </div>
    ),
  },
  {
    accessorKey: 'situation',
    accessorFn: (row) => row.situation,
    header: 'Situação geral',
    meta: {
      headerClassName: '!text-center',
    },
    cell: (props) => (
      <div className="m-auto ml-4 flex items-center justify-center">
        <TableCellText
          text={'Situação geral:'}
          align="center"
          textAlign="text-center"
          className="font-bold uppercase text-primary"
          width="7rem"
        />
        <TableCellText
          text={props.getValue() as string}
          align="center"
          width="4rem"
          textAlign="text-center"
          className="font-bold"
        />
      </div>
    ),
  },
];
