import { z } from 'zod';

const EmployeeSchema = z.object({
  id: z.number(),
  name: z.string(),
  alias: z.string().optional().nullable().default(''),
  email: z.string().optional().default(''),
  pole: z.string().optional().default(''),
  employeeNumber: z.string(),
  admissionDate: z.string(),
  position: z.string(),
  address: z.string().nullable(),
  state: z.string(),
  neighborhood: z.string().nullable(),
  city: z.string().nullable(),
  street: z.string().nullable(),
  houseNumber: z.string().nullable(),
  addressDetails: z.string().nullable(),
  zipCode: z.string().nullable(),
  workStation: z.string(),
  management: z.string().nullable(),
  managementId: z.number().optional(),
  regime: z.string(),
  manager: z.string().nullable(),
  managerSGFT: z.string().optional(),
  structure: z.string().optional(),
  team: z.string().optional(),
  areaCoordinator: z.string().optional(),
  vacation_relief_worker: z.boolean().optional(),
  fire_warden: z.boolean().optional(),
  on_notice: z.boolean().optional(),
  structure_area: z.string().optional(),
  structure_sector: z.string().optional(),
  notAllocated: z.boolean().optional(),
  nextVacationStartDate: z.string().optional(),
  nextVacationEndDate: z.string().optional(),
  status: z.string().optional(),
});

export type Employee = z.infer<typeof EmployeeSchema>;

export const EmployeesSchema = z.object({
  employees: z.array(EmployeeSchema),
  count: z.number(),
  nextPage: z.number().optional(),
});

export type Employees = z.infer<typeof EmployeeSchema>;

export enum EmployeeStatus {
  ACTIVE = 'Ativo',
  INSS = 'Af.Previdência',
  SUSPENDED = 'Contrato de Trabalho Suspenso',
  FIRED = 'Demitido',
  VACATION = 'Férias',
  MATERNITY_LEAVE = 'Licença Mater.',
}
