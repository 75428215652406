import React from 'react';

import InfoSection from '../molecules/InfoSection';

interface SectionContent {
  title?: string;
  subtitle?: string;
  content: any;
  minHeight: string;
  containerClass: string;
  headerClass: string;
  contentClass: string;
  subtitleClassname?: string;
}

interface Section {
  sectionsPerRow: 1 | 2;
  content: SectionContent[];
}

interface InfoLayoutProps {
  title?: string;
  headerClass?: string;
  sections: Section[];
}

const InfoLayout: React.FC<InfoLayoutProps> = ({
  title,
  headerClass,
  sections,
}) => {
  return (
    <div className="w-full">
      {title && (
        <header className={headerClass}>
          <h1 style={{ width: '100%', whiteSpace: 'nowrap' }}>{title}</h1>
          <img
            src="/origem.png"
            alt="Logo Origem"
            className="ml-auto"
            style={{ width: '350', height: '150px', marginLeft: '200px' }}
          />
        </header>
      )}

      {sections.map((section, index) => (
        <div
          key={index}
          className={`grid gap-0.5 ${section.sectionsPerRow === 2 ? 'grid-cols-2' : 'grid-cols-1'}`}
        >
          {section.content.map((content, idx) => (
            <InfoSection
              key={idx}
              title={content.title}
              subtitle={content.subtitle}
              content={content.content}
              minHeight={content.minHeight}
              containerClass={content.containerClass}
              headerClass={content.headerClass}
              contentClass={content.contentClass}
              subtitleClassname={content?.subtitleClassname}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default InfoLayout;
