export const possibleEnvs = {
  local: {
    name: 'Local',
    color: 'bg-yellow-600',
  },
  homolog: {
    name: 'Homolog',
    color: 'bg-rose-600',
  },
};
