import { MouseEvent, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import { NewJobTitle } from '@/types/NewJobTitle';
import { removeTimeZone } from '@/utils/formatDate';

import PrintRoles from '../organisms/PrintJobTitlePDF';
import RoleHistoryModal from '../organisms/RoleHistoryModal';
import RoleModal from '../organisms/RoleModal';
import LightTooltip from './LightTooltip';
import SimpleMenu from './Menu';
import TableCellDate from './TableCellDate';

interface JobTitleLineProps {
  newJobTitle: NewJobTitle;
}
export const JobTitleLine = ({ newJobTitle }: JobTitleLineProps) => {
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const [isRoleHistoryModalOpen, setIsRoleHistoryModalOpen] = useState(false);
  const printRef = useRef<HTMLDivElement>(null);

  const pdfName =
    newJobTitle.seniorityLevel.name && newJobTitle.seniorityLevel.name !== 'N/A'
      ? `Descrição de Cargo - ${newJobTitle.generalInformationJobTitle.name.toUpperCase()} ${newJobTitle.seniorityLevel.name.toUpperCase()}`
      : `Descrição de Cargo - ${newJobTitle.generalInformationJobTitle.name.toUpperCase()}`;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${pdfName}`,
  });

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };
  const options = [
    {
      name: 'Editar Informações',
      onClick: () => {
        const urlRedmine = import.meta.env.VITE_REDMINE_URL;

        const path = `${urlRedmine}/issues/${newJobTitle.generalInformationJobTitle?.issueId}`;
        window.open(path);
      },
      // disabled: !newJobTitle.active,
      disabled: true,
    },
    {
      name: 'Consultar Versões',
      onClick: () => {
        setIsRoleHistoryModalOpen(true);
      },
      disabled: false,
    },
    {
      name: 'Exportar Descrição do Cargo',
      onClick: () => {
        handlePrint();
      },
      disabled: false,
    },
  ];

  return (
    <div className="flex h-[3rem] w-full items-center justify-between rounded-lg bg-[#ffffff] p-8 transition-all duration-500 ease-in-out hover:bg-gray-300">
      <div className="flex w-[60%]">
        <div className="flex items-center justify-between gap-4">
          <LightTooltip
            title={`${newJobTitle?.generalInformationJobTitle?.name} ${newJobTitle?.seniorityLevel?.name}`}
            open={tooltipEnabled}
            onClose={() => setTooltipEnabled(false)}
            arrow
            placement="top-end"
          >
            <div
              className="w-[20rem] cursor-pointer overflow-hidden text-ellipsis text-[14px] font-medium uppercase text-[#4a4a4a]"
              onMouseEnter={handleShouldShow}
              onClick={() => setIsRoleModalOpen(true)}
            >
              {`${newJobTitle?.generalInformationJobTitle?.name} ${newJobTitle?.seniorityLevel?.name && newJobTitle?.seniorityLevel?.name !== 'N/A' ? newJobTitle?.seniorityLevel?.name : ''}`}
            </div>
          </LightTooltip>
        </div>

        <div className="w-[20rem]">
          {!newJobTitle.active && (
            <div className="w-[4rem] rounded-xl bg-[#d9d9d9] text-center text-[12px] font-medium text-[#5a5a5a]	">
              INATIVO
            </div>
          )}
        </div>
      </div>
      <div className="flex w-[40%] items-center justify-end text-[11px] text-[#848484]">
        <div className="font-medium">
          {' '}
          Versão: {newJobTitle.generalInformationJobTitle?.version}
        </div>
        <div className="mx-2">●</div>
        <div className="mr-1">Última edição:</div>
        <div className="mr-1 font-medium">
          {' '}
          <TableCellDate
            className="text-[10px] font-normal text-[#848484]"
            date={removeTimeZone(
              new Date(newJobTitle.seniorityLevel.updated_at),
            )}
          />
        </div>
        <SimpleMenu options={options} upDotsButton upDotsButtonColor="action" />
      </div>
      {isRoleModalOpen && (
        <RoleModal
          modalIsOpen={isRoleModalOpen}
          handleClose={() => setIsRoleModalOpen(false)}
          newJobTitle={newJobTitle}
        />
      )}

      {isRoleHistoryModalOpen && (
        <RoleHistoryModal
          newJobTitle={newJobTitle}
          modalIsOpen={isRoleHistoryModalOpen}
          handleClose={() => setIsRoleHistoryModalOpen(false)}
        />
      )}
      <div className="relative  hidden h-[100%] w-full flex-col">
        <PrintRoles ref={printRef} newJobTitle={newJobTitle} />
      </div>
    </div>
  );
};
