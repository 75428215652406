import { forwardRef, useImperativeHandle, useRef } from 'react';

interface CheckboxComponentProps {
  defaultChecked: boolean;
  onChange: any;
  disabled?: boolean;
  checked?: boolean;
}

interface CheckboxRef {
  isChecked: () => boolean;
  setChecked: (value: boolean) => void;
}

const Checkbox = forwardRef<CheckboxRef, CheckboxComponentProps>(
  ({ defaultChecked, onChange, disabled = false, checked, ...rest }, ref) => {
    const checkboxRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
      isChecked: () => checkboxRef.current?.checked || false,
      setChecked: (value: boolean) => {
        if (checkboxRef.current) {
          checkboxRef.current.checked = value;
        }
      },
    }));

    return (
      <input
        type="checkbox"
        checked={checked}
        ref={checkboxRef}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
