import { z } from 'zod';

import { CombinedJobTitle } from '@/types/NewJobTitle';

type CombinedJobTitleKeys = keyof CombinedJobTitle;

export const RoleConstant: { value: CombinedJobTitleKeys; label: string }[] = [
  { value: 'name', label: 'Título do cargo' },
  { value: 'direction', label: 'Diretoria' },
  { value: 'management', label: 'Gerência' },
  { value: 'seniorityLevelName', label: 'Senioridade do Cargo' },
  { value: 'workStations', label: 'Local de Trabalho' },
  { value: 'objective', label: 'Objetivo do Cargo' },
  { value: 'responsibilities', label: 'Funções e Responsabilidades' },
  { value: 'educationalRequirements', label: 'Requisitos Educacionais' },
  { value: 'professionalRecords', label: 'Registros Profissionais' },
  { value: 'otherRequirements', label: 'Outros Requisitos' },
  {
    value: 'educationalRequirementsOptional',
    label: 'Requisitos Educacionais Desejáveis',
  },
  {
    value: 'professionalRecordsOptional',
    label: 'Registros Profissionais Desejáveis',
  },
  { value: 'otherRequirementsOptional', label: 'Outros Requisitos Desejáveis' },
  { value: 'experience', label: 'Experiência' },
  { value: 'skillsAndCompetencies', label: 'Habilidades e Competências' },
  { value: 'GHEPerWorkStation', label: 'GHE por local de trabalho' },
];

const RolesFilterSingleOption = z
  .object({
    name: z.string(),
  })
  .transform(({ name }) => {
    return {
      value: name,
      label: name,
    };
  });

const RolesFilterOption = z
  .object({
    id: z
      .union([z.number(), z.string()])
      .transform((id) => String(id))
      .pipe(z.string()),
    name: z.string(),
  })
  .transform(({ id, name }) => ({
    value: id,
    label: name,
  }));

export const RoleFilterOptionsSchema = z.object({
  titles: z.array(RolesFilterOption).default([]),
  levels: z.array(RolesFilterOption).default([]),
  directions: z.array(RolesFilterOption).default([]),
  managements: z.array(RolesFilterOption).default([]),
  workStations: z.array(RolesFilterOption).default([]),
  statuses: z.array(RolesFilterSingleOption).default([]),
  requirementTypes: z.array(RolesFilterSingleOption).default([]),
  educationalRequirements: z.array(RolesFilterOption).default([]),
  professionalRecords: z.array(RolesFilterOption).default([]),
  otherRequirements: z.array(RolesFilterOption).default([]),
});

export type RolesFiltersOptions = z.infer<typeof RoleFilterOptionsSchema>;
