import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { GoAlertFill } from 'react-icons/go';
import { toast } from 'react-toastify';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { ReallocationStructure } from '@/constants/AllocationConstants';
import {
  AllocationFormRequest,
  AllocationMotive,
  allocationMotiveValues,
  AllocationRequest,
  allocationRequestMessageSchema,
  allocationRequestResponseSchema,
  FormSchema,
} from '@/constants/AllocationRequest';
import api from '@/services/apiSgft';
import { AllocationRequestEditData } from '@/state/AllocationRequestEditData.atom';
import { AllocationTypeAtom } from '@/state/AllocationType.atom';

import { Button } from '../atoms/Button';
import ControlledCalendar from '../atoms/ControlledCalendar';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import FieldComponent from '../atoms/FieldComponent';
import FileUploadComponent from '../atoms/FileUpload';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import { ViewFiles } from '../atoms/ViewFiles';
import ModalConfirmation from './ConfirmationModal';

interface ManagerAllocationFormProps {
  employeeId: number | undefined;
  teamStructures: ReallocationStructure[];
  defaultValues?: AllocationFormRequest;
}

export const ManagerAllocationForm = ({
  employeeId,
  teamStructures,
  defaultValues,
}: ManagerAllocationFormProps) => {
  const setType = useSetRecoilState(AllocationTypeAtom);
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState<AllocationFormRequest | null>(null);
  const resetEditData = useResetRecoilState(AllocationRequestEditData);
  const queryClient = useQueryClient();
  const form = useForm<AllocationFormRequest>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const teamStructureWatch = form.watch('teamStructureId');
  const motive = form.watch('motive');
  const allocationDateWatch = form.watch('date');
  const evidence = form.watch('evidence');
  const files = form.watch('files');

  const isDeterminacaoDaEmpresa =
    motive === AllocationMotive.DeterminacaoDaEmpresa;

  async function onSubmit(data: AllocationFormRequest) {
    setFormData(data);
    const response = await api.get(
      '/team-employee/allocation-request/message',
      {
        params: {
          employeeId,
          startDate: data.date,
        },
      },
    );
    const parsedResponse = allocationRequestMessageSchema.safeParse(
      response.data,
    );
    if (!parsedResponse.success) {
      toast.error('Não foi possível enviar a solicitação', {
        theme: 'colored',
        toastId: 'error',
      });
      return;
    }
    setMessage(parsedResponse.data.message);
    setIsModalConfirmationOpen(true);
  }

  async function handleSaveData() {
    if (formData) {
      const requestData: AllocationRequest = {
        employeeId,
        teamId: formData.teamId,
        date: formData.date,
        motive: formData.motive,
        justification: formData.justification,
      };

      if (isDeterminacaoDaEmpresa && !(formData.evidence || formData.files)) {
        toast.error(
          'É necessário enviar a comprovação para esse tipo de motivo',
          {
            theme: 'colored',
            toastId: 'error',
          },
        );
        return;
      }

      try {
        const response = formData.allocationRequestId
          ? await api.put(
              `/team-employee/update-allocation-request/${formData.allocationRequestId}`,
              requestData,
            )
          : await api.post(
              `/team-employee/create-allocation-request`,
              requestData,
            );

        const allocationResponse = allocationRequestResponseSchema.safeParse(
          response.data,
        );

        const id = formData.allocationRequestId
          ? formData.allocationRequestId
          : allocationResponse.success
            ? allocationResponse.data.id
            : undefined;

        if (
          id &&
          formData.motive === AllocationMotive.DeterminacaoDaEmpresa &&
          formData.evidence
        ) {
          const files = formData.evidence;
          const fileData = new FormData();
          for (const file of files) {
            fileData.append('files', file);
          }

          fileData.append('id', id.toString());
          await api.post(
            `team-employee/upload-allocation-evidence/${id}`,
            fileData,
          );
        }

        toast.success('Solicitação enviada com sucesso!', {
          theme: 'colored',
        });
        queryClient.invalidateQueries(['allocation-requests', employeeId]);
        reset();
      } catch (e) {
        if (isAxiosError(e)) {
          if (e.response?.data?.message) {
            toast.error(e.response?.data?.message, {
              theme: 'colored',
              toastId: 'error',
            });
          }
        } else {
          toast.error('Não foi possível enviar a solicitação', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      }
    }
  }

  const isAbleToSave =
    form.formState.isValid &&
    !!allocationDateWatch &&
    (isDeterminacaoDaEmpresa ? !!evidence : true);

  const teamStructuresValues = teamStructures.map((teamStructure) => ({
    value: teamStructure.id,
    label: teamStructure.name,
  }));

  const teamValues =
    teamStructures
      .find((teamStructure) => teamStructure.id === teamStructureWatch)
      ?.teams.map((team) => ({
        value: team.id,
        label: team.name,
      })) || [];

  const onFilesSelected = (filesList: FileList | null) => {
    const filesArray = filesList ? Array.from(filesList) : [];
    form.setValue('evidence', filesArray);
  };

  function onCancel() {
    reset();
  }

  function reset() {
    form.reset();
    setType('');
    resetEditData();
  }

  const handleOnDeleteFile = async (id: number) => {
    try {
      await api.delete(`team-employee/delete-evidence/${id}`);
      toast.success('Comprovante deletado com sucesso!', {
        theme: 'colored',
        toastId: 'success',
      });
      const updatedFiles = files?.filter((file) => file.id !== id);
      form.setValue('files', updatedFiles || null);
    } catch (e: unknown) {
      if (isAxiosError(e)) {
        if (e.response?.data?.message) {
          toast.error(e.response?.data?.message, {
            theme: 'colored',
            toastId: 'error',
          });
        }
      } else {
        toast.error('Não foi possível excluir o comprovante', {
          theme: 'colored',
          toastId: 'error',
        });
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div>
          <div className="mb-3 flex w-full gap-4">
            <ControlledSimpleSelect
              title={'Nova Estrutura de Turma'}
              required
              name={'teamStructureId'}
              width="26rem"
              values={teamStructuresValues}
              control={form.control}
              fieldError={form.formState.errors.teamStructureId}
              errorMessage="A estrutura de turma é obrigatória*"
            />
            <ControlledSimpleSelect
              title={'Nova Turma'}
              required
              name={'teamId'}
              width="10rem"
              values={teamValues}
              control={form.control}
              fieldError={form.formState.errors.teamId}
              errorMessage="A turma é obrigatória*"
            />
            <ControlledCalendar
              title="Data da solicitação"
              required
              name="date"
              width="10rem"
              control={form.control}
              fieldError={form.formState.errors.date}
              errorMessage="A data da solicitação é obrigatória*"
              minDate={new Date()}
            />
          </div>
          <div className="mb-3 flex w-full gap-4">
            <UncontrolledInputText
              title="Justificativa"
              width="26rem"
              fieldError={form.formState.errors.justification}
              errorMessage="A justificativa é obrigatória*"
              {...form.register('justification')}
            />
            <ControlledSimpleSelect
              title={'Motivo da Realocação'}
              required
              name={'motive'}
              width="10rem"
              values={allocationMotiveValues}
              control={form.control}
              fieldError={form.formState.errors.motive}
              errorMessage="O motivo de realocação é obrigatório*"
            />
            {isDeterminacaoDaEmpresa && (
              <FieldComponent title="Anexo" width="10rem" required>
                <div
                  className={`flex w-full items-center ${files ? 'justify-between' : 'justify-center'}`}
                >
                  {files && (
                    <ViewFiles
                      files={files}
                      onDeleteFunction={handleOnDeleteFile}
                      deleteFileDescrption={''}
                    />
                  )}
                  <FileUploadComponent
                    onFilesSelected={onFilesSelected}
                    rowId=""
                    addMargin={false}
                  />
                </div>
              </FieldComponent>
            )}
          </div>
        </div>
        {teamStructuresValues.length === 0 && (
          <div className="col-span-4 flex items-center gap-2 font-graphie">
            <GoAlertFill size={20} color="#FEDD00" />
            <span className="text-xs/none">
              A gerência não possuí estruturas que se enquadram para alocação
              desse colaborador. Para criação de uma nova estrutura, abrir um
              chamado para a equipe de suporte.
            </span>
          </div>
        )}
        <div className="flex justify-between p-5">
          <Button
            type="button"
            variant="outline"
            className="min-w-40 font-semibold ring-1"
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button type="submit" disabled={!isAbleToSave} className="min-w-40">
            Solicitar
          </Button>
        </div>
        {isModalConfirmationOpen && (
          <ModalConfirmation
            title=""
            description={message}
            descriptionClassName="whitespace-pre-wrap"
            confirmAction={() => handleSaveData()}
            isOpen={isModalConfirmationOpen}
            onClose={() => setIsModalConfirmationOpen(false)}
            discartAction={() => setIsModalConfirmationOpen(false)}
          />
        )}
      </form>
    </FormProvider>
  );
};
