import { Menu, MenuItem } from '@mui/material';
import Fade from '@mui/material/Fade';
import React from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

export interface AllocationMenuOptions {
  name: string;
  onClick: () => void;
  disabled: boolean;
}
interface MenuProps {
  options: AllocationMenuOptions[];
  editButton?: boolean | undefined;
  deleteButton?: boolean | undefined;
}

const AllocationMenu = ({ options, editButton, deleteButton }: MenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {editButton ? (
          <FaPencilAlt color="blue" />
        ) : deleteButton ? (
          <FaTrashAlt color="blue" />
        ) : (
          <></>
        )}
      </button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {options.map((option: any) => (
          <MenuItem
            key={option.name}
            onClick={() => {
              handleClose();
              option.onClick();
            }}
            disabled={option.disabled}
            sx={{ fontSize: '12px' }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default AllocationMenu;
