import { ButtonHTMLAttributes } from 'react';
import { FaEdit } from 'react-icons/fa';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary';
  displayValue?: string;
}

const EditButton = ({ onClick, color, displayValue, ...rest }: Props) => {
  const buttonStyle = displayValue ? { display: displayValue } : {};

  return (
    <button
      onClick={onClick}
      aria-label="edit"
      {...rest}
      style={buttonStyle}
      className="p-2 disabled:cursor-not-allowed disabled:opacity-35"
    >
      <FaEdit
        className={`ml-auto fill-primary`}
        fontSize="small"
        color={color ? 'primary' : 'inherit'}
        style={{
          fontSize: '18px',
          color: '#9CA3AF',
        }}
      />
    </button>
  );
};

export default EditButton;
