import { atom } from 'recoil';

export interface NewJobTitleAtom extends Record<string, string[] | string> {
  titles: string;
  levels: string[];
  directions: string[];
  managements: string[];
  workStations: string[];
  statuses: string[];
  requirementTypes: string[];
  educationalRequirements: string[];
  professionalRecords: string[];
  otherRequirements: string[];
}

export const newJobTitleAtom = atom<NewJobTitleAtom>({
  key: 'newJobTitle',
  default: {
    titles: '',
    levels: [],
    directions: [],
    managements: [],
    workStations: [],
    statuses: [],
    requirementTypes: [],
    educationalRequirements: [],
    professionalRecords: [],
    otherRequirements: [],
  },
});
