import { Button, styled } from '@mui/material';
import React from 'react';
import { BiSolidCloudUpload } from 'react-icons/bi';
import { CgSpinner } from 'react-icons/cg';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import { RequirementControlAtom } from '@/state/RequirementControl.atom';
import { trackEvent } from '@/utils/trackEvent';

import api from '../../services/apiSgft';
import LightTooltip from '../atoms/LightTooltip';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface RequirementControlUploadButtonProps {
  filteredData: RequirementControlAtom;
}

export const RequirementControlUploadButton = ({
  filteredData,
}: RequirementControlUploadButtonProps) => {
  const [loading, setLoading] = React.useState(false);

  const upload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      try {
        setLoading(true);
        await api.post('requirement-control/upload', formData);
        toast.success(
          'Upload de controle de requisitos realizado com sucesso!',
          {
            theme: 'colored',
            toastId: 'success',
          },
        );
        trackEvent(
          'Upload de controle de requisitos',
          'file_upload',
          file.name,
        );

        queryClient.invalidateQueries(['requirement-control', filteredData]);
      } catch (error: any) {
        trackEvent(
          'Upload de controle de requisitos',
          'file_upload',
          'Erro ao realizar o Upload',
        );
        toast.error(
          error?.response?.data?.message ??
            'Erro ao fazer upload de controle requisitos!',
          {
            theme: 'colored',
            toastId: 'error',
          },
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <LightTooltip title="Atualizar controle de requisitos">
      {loading ? (
        <CgSpinner size={30} color="#c1c1c1" className="h-full animate-spin" />
      ) : (
        <Button
          sx={{
            backgroundColor: 'inherit',
            height: '2.7rem',
            width: '2.7rem',
            padding: '8px',
            minWidth: 0,
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: '#EAEDF4 !important',
            },
          }}
          component="label"
        >
          <BiSolidCloudUpload color="grey" fontSize={'1.7rem'} />
          <VisuallyHiddenInput
            type="file"
            onChange={(e) => upload(e)}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </Button>
      )}
    </LightTooltip>
  );
};
