import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { GoAlertFill } from 'react-icons/go';
import { toast } from 'react-toastify';

import {
  InitialAllocationForm,
  initialAllocationFormSchema,
  InitialAllocationTeamStructure,
} from '@/constants/FirstAllocationConstants';
import { removeTimeZone } from '@/utils/formatDate';
import { trackEvent } from '@/utils/trackEvent';

import api from '../../services/apiSgft';
import { Button } from '../atoms/Button';
import ControlledCalendar from '../atoms/ControlledCalendar';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Line from '../atoms/Line';
import SubtitleText from '../atoms/SubtitleText';
import TextComponent from '../atoms/TextComponent';

interface FirstAllocationCardProps {
  management: string;
  minDate: string | null;
  teamStructures: InitialAllocationTeamStructure[];
  handleClose: () => void;
  employeeId: number;
}

const FirstAllocationCard = ({
  management,
  minDate,
  teamStructures,
  handleClose,
  employeeId,
}: FirstAllocationCardProps) => {
  const queryClient = useQueryClient();
  const form = useForm<InitialAllocationForm>({
    resolver: zodResolver(initialAllocationFormSchema),
    mode: 'onChange',
    defaultValues: {
      teamStructureId: 0,
      teamId: 0,
    },
  });

  const isSubmitting = form.formState.isSubmitting;
  const allocationDateWatch = form.watch('allocationDate');
  const isAbleToSave = form.formState.isValid && !!allocationDateWatch;

  const teamStructuresValues = teamStructures.map((teamStructure) => ({
    value: teamStructure.id,
    label: teamStructure.name,
  }));

  const teamStructureWatch = form.watch('teamStructureId');

  const teamValues =
    teamStructures
      .find((teamStructure) => teamStructure.id === teamStructureWatch)
      ?.teams.map((team) => ({
        value: team.id,
        label: team.name,
      })) || [];

  async function onSubmit(data: InitialAllocationForm) {
    try {
      const response = await api.post('/team-employee/createAllocation', {
        employeeId,
        teamId: data.teamId,
        startDate: data.allocationDate,
      });
      const message =
        response.data?.message || 'Alocação realizada com sucesso!';
      toast.success(message, {
        theme: 'colored',
        toastId: 'success',
      });
      const selectedTeam = teamValues.find(
        (team) => team.value === data.teamId,
      );
      trackEvent(management, 'generate_allocation', selectedTeam?.label || '');
      queryClient.invalidateQueries(['all-employees']);
      handleClose();
    } catch (e) {
      if (isAxiosError(e)) {
        const statusCode = e?.response?.status;
        const errorMessage =
          statusCode && statusCode < 500
            ? e?.response?.data?.message
            : 'Sua solicitação não foi processada. Tente novamente mais tarde!';
        toast.error(errorMessage, {
          theme: 'colored',
          toastId: 'error',
        });
      }
      throw e;
    }
  }

  return (
    <div className="mb-5">
      <SubtitleText subtitle={'Adicionar Primeira Alocação'} />
      <Line />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="mb-3 flex w-full justify-between">
            <TextComponent
              title={'Gerência'}
              width="20rem"
              value={management}
            />
            <ControlledSimpleSelect
              width="10rem"
              control={form.control}
              name="teamStructureId"
              title="Estrutura de Turma"
              values={teamStructuresValues}
              required
              disabled={teamStructuresValues.length === 0}
              fieldError={form.formState.errors.teamStructureId}
              errorMessage="A estrutura de turma é obrigatória*"
            />
            <ControlledSimpleSelect
              width="6rem"
              control={form.control}
              name="teamId"
              title="Turma"
              values={teamValues}
              required
              disabled={teamValues.length === 0}
              fieldError={form.formState.errors.teamId}
              errorMessage="A turma é obrigatória*"
            />
            <ControlledCalendar
              title="Data de alocação"
              required
              name="allocationDate"
              width="10rem"
              control={form.control}
              fieldError={form.formState.errors.allocationDate}
              errorMessage="A data da realocação é obrigatória*"
              minDate={minDate ? removeTimeZone(new Date(minDate)) : undefined}
            />
          </div>
          {teamStructuresValues.length === 0 && (
            <div className="col-span-4 flex items-center gap-2 font-graphie">
              <GoAlertFill size={20} color="#FEDD00" />
              <span className="text-xs/none">
                A gerência não possuí estruturas que se enquadram para alocação
                desse colaborador. Para criação de uma nova estrutura, abrir um
                chamado para a equipe de suporte.
              </span>
            </div>
          )}
          <div className="flex justify-between pt-5">
            <Button
              type="button"
              title="Cancelar"
              variant="link"
              className="min-w-40 font-semibold ring-1"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              className="min-w-40 underline"
              isLoading={isSubmitting}
              disabled={!isAbleToSave}
            >
              Salvar
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FirstAllocationCard;
