import { ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
  fill?: boolean;
  required?: boolean;
  width: string;
}

const FieldComponent = ({ title, children, fill, required, width }: Props) => {
  return (
    <div>
      <div className={`w-[${width}] text-[12px] text-[#4A4A4A]`}>
        {title}
        {required && <span className="text-red">*</span>}
      </div>
      <div
        className={`w-[${width}] flex h-8 items-center justify-center overflow-hidden rounded-[5px] border-[1px] border-[#d8d8d8] ${fill && 'bg-[#f0f0f0]'} px-2 text-[12px]`}
      >
        {children}
      </div>
    </div>
  );
};

export default FieldComponent;
