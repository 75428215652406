import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { RiFileList2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { trackEvent } from '@/utils/trackEvent';

import api from '../../services/apiSgft';
import { RequirementControlAtom } from '../../state/RequirementControl.atom';
import { DateRangeDialog } from '../atoms/DateRangeDialog';
import LightTooltip from '../atoms/LightTooltip';

interface RequirementControlDownloadButtonProps {
  filteredData: RequirementControlAtom;
}

interface DateRange {
  start: string;
  end: string;
}

export const RequirementControlHistoryDownloadButton: React.FC<
  RequirementControlDownloadButtonProps
> = ({ filteredData }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>({ start: '', end: '' });
  const [isEverything, setIsEverything] = useState(false);

  const handleDownload = async (includeDates: boolean) => {
    try {
      const params = {
        employee: filteredData.employee,
        role: filteredData.role,
        management: filteredData.management,
        areaCoordinator: filteredData.areaCoordinator,
        pole: filteredData.pole,
        workstation: filteredData.workstation,
        status: filteredData.status,
        situation: filteredData.situation,
        requirementType: filteredData.requirementType,
        requirementClass: filteredData.requirementClass,
        requirement: filteredData.requirement,
        ...(includeDates &&
          !isEverything && {
            startDate: dateRange.start,
            endDate: dateRange.end,
          }),
      };
      const response = await api.get(`/extraction/requirement-control-history`, {
        params,
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Histórico de Controle de Requisitos - SGFT.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      trackEvent('Download do Historico de Controle de Requisitos', 'file_download', url);
    } catch (e) {
      trackEvent(
        'Download do Historico de Controle de Requisitos',
        'file_download',
        'Erro ao realizar o Download',
      );
      toast.error('Estrutura sem dados', {
        theme: 'colored',
        toastId: 'error',
      });
    } finally {
      setIsDialogOpen(false);
    }
  };

  return (
    <>
      <div className="cursor-pointer">
        <LightTooltip title="Extrair histórico">
          <IconButton onClick={() => setIsDialogOpen(true)}>
            <RiFileList2Line />
          </IconButton>
        </LightTooltip>
      </div>
      <DateRangeDialog
        title={`Selecionar Periodo do Histórico:`}
        dateRange={dateRange}
        setDateRange={setDateRange}
        isEverything={isEverything}
        setIsEverything={setIsEverything}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        onConfirmation={() => handleDownload(!isEverything)}
      />
    </>
  );
};
