import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { Button } from '@/components/atoms/Button';
import BoardExpandedCard from '@/components/molecules/BoardExpandedCard';
import BoardModal from '@/components/molecules/BoardModal';
import ViewScheduleWrapper from '@/components/molecules/ViewScheduleWrapper';
import api from '@/services/apiSgft';

import ErrorIcon from '../../assets/icons/error.svg';
export interface BoardDto {
  id: number;
  name: string;
  active: boolean;
  company: string;
  managements: {
    id: number;
    name: string;
    active: boolean;
    coordinations: {
      id: number;
      name: string;
      active: boolean;
    }[];
  }[];
}

const Board = () => {
  const [isOpenCreate, setIsOpenCreate] = useState(false);

  const getBoardsData = async () => {
    try {
      const response = await api.get('/board/all-data');
      return response.data as BoardDto[];
    } catch (e) {
      toast.error('Erro ao carregar diretorias!', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  const { data, isLoading, isError } = useQuery(
    ['get-all-boards-data'],
    getBoardsData,
  );

  return (
    <div className="custom-scrollbar mt-3 flex h-[90%] w-[95%] flex-col p-2">
      <div className="flex justify-between">
        <div className="mb-2 font-graphie text-[20px] font-bold text-[#193CB9]">
          Cadastro de Diretoria
        </div>
        <Button
          className="h-7 w-40 rounded-full text-xs text-white"
          onClick={() => setIsOpenCreate(true)}
        >
          + Adicionar
        </Button>
      </div>
      <ViewScheduleWrapper isLoading={isLoading} isError={isError}>
        <div className="flex flex-col">
          {data ? (
            data.map((el, index) => {
              return <BoardExpandedCard key={index} board={el} />;
            })
          ) : (
            <div className="h-full w-[95%] px-[1%]">
              <div className="flex h-full w-full flex-col items-center justify-center rounded-md">
                <img src={ErrorIcon} alt="Ícone de erro" className="w-24" />
                <div className="flex flex-col items-center text-center">
                  Nenhuma diretoria encontrada!
                </div>
              </div>
            </div>
          )}
        </div>
      </ViewScheduleWrapper>
      {isOpenCreate && (
        <BoardModal
          isOpen={isOpenCreate}
          onClose={() => setIsOpenCreate(false)}
          type={'create'}
        />
      )}
    </div>
  );
};

export default Board;
