import { ColumnDef } from '@tanstack/react-table';

import { AllocationHistory } from '@/constants/AllocationConstants';
import { userIsRhOrAdmin } from '@/utils/handleSavePermissions';

import TableCellText from '../../components/atoms/TableCellText';
import { removeTimeZone } from '../../utils/formatDate';
import AllocationButtons from '../molecules/AllocationButtons';
import CurrentButton from './CurrentButton';
import TableCellAllocation from './TableCellAllocation';
import TableCellDate from './TableCellDate';

export const AllocationColumns: Array<ColumnDef<AllocationHistory>> = [
  {
    accessorKey: 'management',
    header: 'Gerência',
    cell: (props) => {
      return (
        <div className="w-40">
          <TableCellAllocation
            text={props.getValue() as string}
            width="10rem"
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'teamStructure',
    header: 'Estrutura',
    cell: (props) => {
      return (
        <div className="w-24">
          <TableCellAllocation text={props.getValue() as string} width="6rem" />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'team',
    header: 'Turma',
    cell: (props) => {
      return (
        <div className="w-12">
          <TableCellAllocation text={props.getValue() as string} width="3rem" />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'startDate',
    header: 'A partir de',
    cell: (props) => {
      return (
        <div className="w-20">
          <TableCellDate
            date={
              (props.getValue() as string)
                ? removeTimeZone(new Date(props.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
    enableSorting: false,
  },

  {
    accessorKey: 'endDate',
    header: 'Até',
    cell: (props) => {
      return (
        <div className="w-20">
          {(props.getValue() as string) ? (
            <TableCellDate
              date={removeTimeZone(new Date(props.getValue() as string))}
            />
          ) : (
            <TableCellText text={' - '} />
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'justification',
    header: 'Justificativa',
    cell: (props) => {
      return (
        <div className="w-32">
          <TableCellAllocation
            text={
              (props.getValue() as string)
                ? (props.getValue() as string)
                : ' - '
            }
            width="8rem"
          />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: 'current',
    header: '',
    cell: (props) => {
      return (
        <div>
          <CurrentButton isCurrent={props.getValue() as boolean} />
        </div>
      );
    },
    enableSorting: false,
  },
  {
    id: 'actions',
    header: '',
    cell: (props) => {
      const isRhOrAdmin = userIsRhOrAdmin();
      const canBeEditable = props.cell.row.original.canBeEditable;

      if (isRhOrAdmin && canBeEditable) {
        return (
          <div className="w-20">
            <AllocationButtons allocation={props.cell.row.original} />
          </div>
        );
      }
      return <div className="h-[35px] w-20" />;
    },
    enableSorting: false,
  },
];
