import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { GoAlertFill } from 'react-icons/go';
import { toast } from 'react-toastify';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  AllocationFormRequest,
  AllocationHistory,
  FormSchema,
  ReallocationStructure,
} from '@/constants/AllocationConstants';
import api from '@/services/apiSgft';
import { AllocationEditData } from '@/state/AllocationEditData';
import { AllocationTypeAtom } from '@/state/AllocationType.atom';
import { removeTimeZone } from '@/utils/formatDate';

import { Button } from '../atoms/Button';
import ControlledCalendar from '../atoms/ControlledCalendar';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import ModalConfirmation from './ConfirmationModal';

interface AllocationFormProps {
  teamStructures: ReallocationStructure[];
  employeeId: number | undefined;
  employeeName: string;
  currentAllocation: AllocationHistory | undefined;
  defaultValues?: AllocationFormRequest;
}

export const AllocationForm = ({
  teamStructures,
  employeeId,
  employeeName,
  currentAllocation,
  defaultValues,
}: AllocationFormProps) => {
  const setType = useSetRecoilState(AllocationTypeAtom);
  const resetEditData = useResetRecoilState(AllocationEditData);
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [formData, setFormData] = useState<AllocationFormRequest | null>(null);
  const queryClient = useQueryClient();
  const form = useForm<AllocationFormRequest>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  function onSubmit(data: AllocationFormRequest) {
    setFormData(data);
    setIsModalConfirmationOpen(true);
  }

  async function handleSaveData() {
    if (formData) {
      const method = formData.allocationId ? 'put' : 'post';
      try {
        const response = await api[method](
          `team-employee/${formData.allocationId || ''}`,
          {
            employeeId,
            teamId: formData.teamId,
            startDate: removeTimeZone(formData.date),
            justification: formData.justification,
          },
        );

        toast.success(response.data.message, {
          theme: 'colored',
          toastId: 'success',
        });

        queryClient.invalidateQueries(['all-employees']);
        queryClient.invalidateQueries(['team-structure']);
        queryClient.invalidateQueries(['reallocation', employeeId]);
        reset();
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.response?.data.message, {
            theme: 'colored',
            toastId: 'error',
          });
          return;
        }
        toast.error(
          'Sua solicitação não foi processada. Tente novamente mais tarde!',
          {
            theme: 'colored',
            toastId: 'error',
          },
        );
      }
    }
  }

  const teamStructureWatch = form.watch('teamStructureId');
  const allocationDateWatch = form.watch('date');

  const isAbleToSave = form.formState.isValid && !!allocationDateWatch;

  const teamStructuresValues = teamStructures.map((teamStructure) => ({
    value: teamStructure.id,
    label: teamStructure.name,
  }));

  const teamValues =
    teamStructures
      .find((teamStructure) => teamStructure.id === teamStructureWatch)
      ?.teams.map((team) => ({
        value: team.id,
        label: team.name,
      })) || [];

  function onCancel() {
    reset();
  }

  function reset() {
    form.reset();
    setType('');
    resetEditData();
  }

  const generateConfirmatiomMessage = () => {
    if (formData) {
      const { teamId, teamStructureId } = formData;
      const teamStructureName = teamStructures.find(
        (teamStructure) => teamStructure.id === teamStructureId,
      )?.name;
      const teamName = teamValues.find((team) => team.value === teamId)?.label;
      const isDifferentTeamStructure =
        teamStructureId !== currentAllocation?.teamStructureId;
      const isDifferentTeam = teamId !== currentAllocation?.teamId;

      if (isDifferentTeamStructure) {
        return `Tem certeza que deseja atualizar a alocação do colaborador ${employeeName} da estrutura ${currentAllocation?.teamStructure} para a estrutura ${teamStructureName}?`;
      }
      if (isDifferentTeam) {
        return `Tem certeza que deseja atualizar a alocação do colaborador ${employeeName} da turma ${currentAllocation?.team} para a turma ${teamName}?`;
      }
      return `Tem certeza que deseja atualizar a alocação do colaborador ${employeeName}?`;
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div>
          <div className="mb-3 flex w-full gap-4">
            <ControlledSimpleSelect
              title={'Nova Estrutura de Turma'}
              required
              name={'teamStructureId'}
              width="26rem"
              values={teamStructuresValues}
              control={form.control}
              fieldError={form.formState.errors.teamStructureId}
              errorMessage="A estrutura de turma é obrigatória*"
            />
            <ControlledSimpleSelect
              title={'Nova Turma'}
              required
              name={'teamId'}
              width="10rem"
              values={teamValues}
              control={form.control}
              fieldError={form.formState.errors.teamId}
              errorMessage="A turma é obrigatória*"
            />
            <ControlledCalendar
              title="Data da alocação"
              required
              name="date"
              width="11rem"
              control={form.control}
              fieldError={form.formState.errors.date}
              errorMessage="A data da alocação é obrigatória*"
              minDate={new Date()}
            />
          </div>
          <div className="mb-3 w-full">
            <UncontrolledInputText
              title="Justificativa"
              fullWidth
              fieldError={form.formState.errors.justification}
              errorMessage="A justificativa é obrigatória*"
              {...form.register('justification')}
            />
          </div>
        </div>
        {teamStructuresValues.length === 0 && (
          <div className="col-span-4 flex items-center gap-2 font-graphie">
            <GoAlertFill size={20} color="#FEDD00" />
            <span className="text-xs/none">
              A gerência não possuí estruturas que se enquadram para alocação
              desse colaborador. Para criação de uma nova estrutura, abrir um
              chamado para a equipe de suporte.
            </span>
          </div>
        )}
        <div className="flex justify-between p-5">
          <Button
            type="button"
            variant="outline"
            className="min-w-40 font-semibold ring-1"
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button type="submit" disabled={!isAbleToSave} className="min-w-40">
            Salvar
          </Button>
        </div>
        {isModalConfirmationOpen && (
          <ModalConfirmation
            title=""
            description={generateConfirmatiomMessage()}
            confirmAction={handleSaveData}
            isOpen={isModalConfirmationOpen}
            onClose={() => setIsModalConfirmationOpen(false)}
            discartAction={() => setIsModalConfirmationOpen(false)}
          />
        )}
      </form>
    </FormProvider>
  );
};
