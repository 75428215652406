export const UserTypes = {
  Admin: 'SGFT.Admin',
  Sop: 'SGFT.Sop',
  Rh: 'SGFT.Rh',
  Regulatorio: 'SGFT.Regulatorio',
  RhTreinamento: 'SGFT.Training.Rh',
  QsmsTreinamento: 'SGFT.Training.Qsms',
  User: 'SGFT.User',
  ReadOnly: 'SGFT.ReadOnly',
  OnDutyWorker: 'SGFT.OnDutyWorker',
  Manager: 'SGFT.Manager',
};

export const PermissionMessages = {
  NoPermission: 'Usuário sem permissão',
  OutOfPolicyNoPermission: 'Somente RH pode salvar férias fora da política',
};
