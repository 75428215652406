import { z } from 'zod';

const initialAllocationTeamSchema = z.object({
  id: z.number(),
  name: z.string(),
});

const initialAllocationTeamStructureSchema = z.object({
  id: z.number(),
  name: z.string(),
  teams: z.array(initialAllocationTeamSchema),
});

export const initialAllocationSchema = z.object({
  name: z.string(),
  employeeNumber: z.string(),
  management: z.string().optional(),
  managementId: z.number().optional(),
  vacationReliefWorker: z.boolean(),
  minDate: z.string().nullable(),
  teamStructures: z.array(initialAllocationTeamStructureSchema),
});

export type InitialAllocation = z.infer<typeof initialAllocationSchema>;
export type InitialAllocationTeam = z.infer<typeof initialAllocationTeamSchema>;
export type InitialAllocationTeamStructure = z.infer<
  typeof initialAllocationTeamStructureSchema
>;

export const initialAllocationFormSchema = z.object({
  teamStructureId: z.number().min(1),
  teamId: z.number().min(1),
  allocationDate: z.coerce.date(),
});

export type InitialAllocationForm = z.infer<typeof initialAllocationFormSchema>;
