import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { BoardDto } from '@/pages/Board';

import CoordinationRegisterCard from './CoordinationRegisterCard';

interface Props {
  index: number;
  data?: BoardDto;
}

const CoordinationRegister = ({ index, data }: Props) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `managements.${index}.coordinations`,
  });

  const addCoordination = () => {
    append({
      name: '',
    });
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({
        name: 'N/A',
      });
    }
  }, [fields]);

  return (
    <div>
      {fields.map((el, idx) => (
        <CoordinationRegisterCard
          key={data?.managements[index]?.coordinations[idx]?.id ?? undefined}
          index={index}
          idx={idx}
          control={control}
          remove={remove}
          data={data}
        />
      ))}
      <button
        type="button"
        className={`mt-2 flex justify-start text-[12px] font-semibold ${(data && !data.active) || (data?.managements[index] && !data.managements[index].active) ? 'text-gray-400' : 'text-[#193CB9]'}`}
        onClick={addCoordination}
        disabled={
          (data && !data.active) ||
          (data?.managements[index] && !data.managements[index].active)
        }
      >
        + Adicionar Coordenação
      </button>
    </div>
  );
};
export default CoordinationRegister;
