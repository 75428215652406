import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { Reallocation } from '@/constants/AllocationConstants';
import { DeallocationEditData } from '@/state/AllocationDataType.atom';
import { AllocationEditData } from '@/state/AllocationEditData';
import { AllocationRequestEditData } from '@/state/AllocationRequestEditData.atom';
import { AllocationTypeAtom } from '@/state/AllocationType.atom';
import { userIsRhOrAdmin } from '@/utils/handleSavePermissions';

import Line from '../atoms/Line';
import SubtitleText from '../atoms/SubtitleText';
import { AllocationForm } from './AllocationForm';
import { DesallocateForm } from './DesallocateForm';
import { ManagerAllocationForm } from './ManagerAllocationForm';

interface Props {
  employeeId: number | undefined;
  data: Reallocation;
}

const AllocationCard = ({ employeeId, data }: Props) => {
  const isRhOrAdmin = userIsRhOrAdmin();
  const allocationEditData = useRecoilValue(AllocationEditData);
  const deallocationEditData = useRecoilValue(DeallocationEditData);
  const allocationRequestEditData = useRecoilValue(AllocationRequestEditData);
  const type = useRecoilValue(AllocationTypeAtom);
  const currentAllocation = data.allocationHistories.find(
    (allocation) => allocation.current,
  );

  const subtitle = useMemo(() => {
    if (type === 'deallocate') {
      return 'Desalocar colaborador da gerência';
    } else if (type === 'create') {
      return isRhOrAdmin ? 'Atualizar Realocação' : 'Atualizar Solicitação';
    } else {
      return isRhOrAdmin ? 'Editar Troca' : 'Editar Solicitação';
    }
  }, [type, isRhOrAdmin]);

  return (
    <div className="mb-5">
      {type !== '' && (
        <div>
          <SubtitleText subtitle={subtitle} />
          <Line />
          {type === 'deallocate' ? (
            <DesallocateForm
              employeeId={employeeId}
              defaultValues={
                deallocationEditData.allocationId
                  ? {
                      allocationId: deallocationEditData.allocationId,
                      justification: deallocationEditData.justification,
                      date: deallocationEditData.date,
                    }
                  : undefined
              }
            />
          ) : isRhOrAdmin ? (
            <AllocationForm
              employeeId={employeeId}
              teamStructures={data.teamStructures}
              employeeName={data.name}
              currentAllocation={currentAllocation}
              defaultValues={
                allocationEditData.allocationId
                  ? {
                      allocationId: allocationEditData.allocationId,
                      teamId: allocationEditData.teamId,
                      teamStructureId: allocationEditData.teamStructureId,
                      date: allocationEditData.date,
                      justification: allocationEditData.justification,
                    }
                  : undefined
              }
            />
          ) : (
            <ManagerAllocationForm
              employeeId={employeeId}
              teamStructures={data.teamStructures}
              defaultValues={
                allocationRequestEditData.allocationRequestId
                  ? {
                      allocationRequestId:
                        allocationRequestEditData.allocationRequestId,
                      teamId: allocationRequestEditData.teamId,
                      teamStructureId:
                        allocationRequestEditData.teamStructureId,
                      date: allocationRequestEditData.date,
                      justification: allocationRequestEditData.justification,
                      motive: allocationRequestEditData.motive,
                      files: allocationRequestEditData.files,
                    }
                  : undefined
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AllocationCard;
