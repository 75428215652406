import { useState } from 'react';

import { cn } from '@/utils/cn';

import LightTooltip from './LightTooltip';

interface Props {
  text: string;
  isName?: boolean;
  width?: string;
  ifEmptyString?: string;
  containerClassName?: string;
  reduceStringIndex?: number;
}

const TableCellAllocation = ({
  text,
  isName,
  width,
  ifEmptyString,
  reduceStringIndex,
  containerClassName,
}: Props) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  let textToRender = text;

  const handleTransformText = (title: string, maxLength: number) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + '...';
    } else {
      return title;
    }
  };

  if (reduceStringIndex) {
    textToRender = handleTransformText(text, reduceStringIndex);
  }

  const handleMouseEnter = () => {
    if (textToRender.length > 150 || isName) {
      setTooltipEnabled(true);
    }
  };

  const handleMouseLeave = () => {
    setTooltipEnabled(false);
  };

  return (
    <LightTooltip
      title={text}
      open={tooltipEnabled}
      onClose={handleMouseLeave}
      arrow
      placement="top-end"
    >
      {text && text !== '-' ? (
        <div
          className={cn(
            `flex w-[${width}] w-full justify-start whitespace-nowrap`,
            containerClassName,
          )}
        >
          <p
            className="overflow-hidden text-ellipsis whitespace-break-spaces text-xs text-gray-600"
            onMouseEnter={handleMouseEnter}
          >
            {textToRender as string}
          </p>
        </div>
      ) : (
        <div className={` w-[${width}] text-center text-[11px] text-[#4A4A4A]`}>
          {ifEmptyString}
        </div>
      )}
    </LightTooltip>
  );
};

export default TableCellAllocation;
