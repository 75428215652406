import React from 'react';

import { cn } from '@/utils/cn';

interface InfoSectionProps {
  title?: string;
  subtitle?: string;
  content: any;
  minHeight: string;
  containerClass: string;
  headerClass: string;
  contentClass: string;
  subtitleClassname?: string;
  containerSubtitleClassname?: string;
}

const InfoSection: React.FC<InfoSectionProps> = ({
  title,
  subtitle,
  content,
  minHeight,
  containerClass,
  headerClass,
  contentClass,
  subtitleClassname,
  containerSubtitleClassname,
}) => {
  const sectionStyle = {
    minHeight,
  };

  return (
    <div className={containerClass} style={sectionStyle}>
      {title && (
        <header className={headerClass}>
          <h2>{title}</h2>
        </header>
      )}
      {subtitle ? (
        <div className={containerSubtitleClassname}>
          <div
            className={cn(
              `pl-6 text-[25px] font-semibold text-[#193CB9]`,
              subtitleClassname,
            )}
          >
            {subtitle}
          </div>
          <hr className="border-gray-300" />
          <div className={contentClass}>
            {typeof content === 'string' ? <p>{content}</p> : content}
          </div>
        </div>
      ) : (
        <div className={contentClass}>
          {typeof content === 'string' ? <p>{content}</p> : content}
        </div>
      )}
    </div>
  );
};

export default InfoSection;
