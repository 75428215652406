import { useState } from 'react';

import EmptyIcon from '@/assets/icons/empty.svg';
import { columns } from '@/constants/tableColumns/RequirementControlColumns';
import { columns as employeeColumns } from '@/constants/tableColumns/RequirementControlEmployeeColumns';
import { RequirementControl } from '@/types/RequirementControl';
import { cn } from '@/utils/cn';

import Table from './Table';

interface RequirementControlCardProps {
  requirementControl: RequirementControl;
}
const RequirementControlCard = ({
  requirementControl,
}: RequirementControlCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleCard = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="relative flex min-w-full flex-col items-start justify-center rounded-lg">
      <hr className="absolute left-[420px] z-[100] h-full w-1 bg-background"></hr>
      {/* <div className="flex min-h-16 w-full items-center justify-start gap-2"> */}
      <div
        className={cn(
          'flex min-h-16 flex-col items-end justify-start gap-4 rounded-lg bg-white text-xs text-gray-600',
          isOpen ? 'rounded-b-none' : '',
        )}
      >
        <div className="flex w-full items-center justify-start">
          <div className="min-h-2 w-full px-4">
            <Table
              columns={employeeColumns(isOpen, handleToggleCard)}
              data={[
                {
                  ...requirementControl.employee,
                  requirements: requirementControl.requirements.length,
                  situation: requirementControl.requirements.some(
                    (el) => el.situation === 'Irregular',
                  )
                    ? 'Irregular'
                    : 'Regular',
                },
              ]}
              showHeader={false}
              isWhiteTable={true}
              tBodyClassname="border-none"
              trBodyClassname="border-none flex items-center justify-center h-16 rounded-lg"
              tdHeaderClassname="bg-transparent text-blue-800"
              trHeaderClassname="bg-transparent my-4 rounded-md"
              containerClassname="border-separate border-spacing-y-4"
            />
          </div>
        </div>
        {isOpen &&
          (requirementControl.requirements.length > 0 ? (
            <div className="custom-scrollbar flex h-full min-h-24 w-[82.5%] flex-col items-center justify-end gap-2 overflow-y-scroll bg-white px-4 py-2">
              <Table
                columns={columns}
                data={requirementControl.requirements}
                isWhiteTable={true}
                tBodyClassname="border-none"
                trBodyClassname="border-none"
                tdHeaderClassname="bg-transparent"
                trHeaderClassname="bg-transparent my-4 rounded-md"
                tdClassname="px-0 align-top bg-background border-b-8 border-white"
                containerClassname="border-separate border-spacing-y-4 w-full"
              />
            </div>
          ) : (
            <div className="flex min-h-40 w-full flex-col items-center justify-center rounded-b-md rounded-t-none bg-white pb-2">
              <img src={EmptyIcon} className="w-32" />
              <div className="w-full text-center text-xs">
                <span className="font-semibold text-blue-800">
                  Nenhum requisito cadastrado para esse colaborador!
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default RequirementControlCard;
