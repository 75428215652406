import { NewJobTitle } from '@/types/NewJobTitle';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

interface SectionContent {
  title?: string;
  subtitle?: string;
  content: any;
  minHeight: string;
  containerClass: string;
  headerClass: string;
  contentClass: string;
  subtitleClassname?: string;
}

interface Section {
  sectionsPerRow: 1 | 2;
  content: SectionContent[];
}

const separateRequirements = (jobTitle: NewJobTitle) => {
  const requirementsLists = {
    mandatoryEducationalRequirements: [] as string[],
    optionalEducationalRequirements: [] as string[],
    mandatoryProfessionalRequirements: [] as string[],
    optionalProfessionalRequirements: [] as string[],
    optionalOtherRequirements: [] as string[],
    mandatoryOtherRequirements: [] as string[],
  };

  jobTitle.SelectedRequirements.forEach(
    ({ Requirements: { requirement, requirementType }, required }) => {
      const keySuffix =
        requirementType === 'Requisitos Educacionais'
          ? 'Educational'
          : requirementType === 'Registros Profissionais'
            ? 'Professional'
            : 'Other';
      const keyPrefix = required ? 'mandatory' : 'optional';
      const key =
        `${keyPrefix}${keySuffix}Requirements` as keyof typeof requirementsLists;
      requirementsLists[key].push(requirement);
    },
  );

  return requirementsLists;
};

const baseContainerClass =
  'bg-white p-1 border-l border-r border-b border-gray-300 pb-0';
const baseHeaderClass =
  'bg-[#EDF0FA] flex items-center p-1 pl-4 mb-1 text-[#193CB9] text-[25px] font-semibold';
const baseContentClass = 'text-gray-700 p-4 text-[20px]';

export const getJobInfo = async (newJobTitle: NewJobTitle) => {
  // const response = await api.get('/new-job-title/newJobTitleById', {
  //   params: {
  //     newJobTitleId,
  //   },
  // });

  const job: NewJobTitle = newJobTitle;
  const functions: string[] = [job.responsibilities];
  const {
    mandatoryEducationalRequirements,
    optionalEducationalRequirements,
    mandatoryProfessionalRequirements,
    optionalProfessionalRequirements,
    optionalOtherRequirements,
    mandatoryOtherRequirements,
  } = separateRequirements(job);
  const workExperience: string[] = [job.experience];
  const skills: string[] = [job.skillsAndCompetencies];

  const RoleExtractionFirstPage: Section[] = [
    {
      sectionsPerRow: 1,
      content: [
        {
          title: '',
          content: (
            <div className="grid h-full grid-cols-[1fr,2fr,2fr,2fr] gap-0 rounded p-2">
              <div>Versão</div>
              <div>Criador</div>
              <div>Revisor</div>
              <div>Aprovador</div>

              <div>{job.generalInformationJobTitle?.version ?? ''}</div>
              <div className="max-w-40 overflow-hidden break-words">
                {job.creator ? getFirstAndLastName(job.creator) : ''}
              </div>
              <div className="max-w-40 overflow-hidden break-words">
                {job.revisor ? getFirstAndLastName(job.revisor) : ''}
              </div>
              <div className="max-w-40 overflow-hidden break-words">
                {job.secondValidator
                  ? getFirstAndLastName(job.secondValidator)
                  : ''}
              </div>
            </div>
          ),
          minHeight: '7vh',
          containerClass: `${baseContainerClass} mb-8 border-t`,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Título do Cargo',
          content: `${job.generalInformationJobTitle?.name?.toUpperCase() ?? ''}`,
          minHeight: '7vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Nível',
          content: `${job.seniorityLevel?.name?.toUpperCase() ?? ''}`,
          minHeight: '7vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
      ],
    },
    {
      sectionsPerRow: 2,
      content: [
        {
          title: 'Diretoria',
          content: `${job.generalInformationJobTitle.GeneralInformationJobTitleDirection.map((generalInformationJobTitleDirection) => generalInformationJobTitleDirection.Direction?.name?.toUpperCase()).join(', ') ?? ''}`,
          minHeight: '7vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
        {
          title: 'Gerência',
          content: `${job.generalInformationJobTitle.GeneralInformationJobTitleManagement.map((generalInformationJobTitleManagement) => generalInformationJobTitleManagement.Management?.name?.toUpperCase()).join(', ') ?? ''}`,
          minHeight: '7vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'GHE por Local de Trabalho',
          content: (
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="w-1/2 border border-gray-300 px-4 py-2 text-center">
                    Local de Trabalho
                  </th>
                  <th className="w-1/2 border border-gray-300 px-4 py-2 text-center">
                    GHE
                  </th>
                </tr>
              </thead>
              <tbody>
                {job.generalInformationJobTitle.ghe.map(
                  (ghePerWorkStation, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 px-4 py-2 ">
                        {ghePerWorkStation.workStation?.name}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {ghePerWorkStation.ghe}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          ),
          minHeight: '7vh',
          containerClass: `${baseContainerClass}`,
          headerClass: baseHeaderClass,
          contentClass: baseContentClass,
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Objetivo do Cargo',
          content: `${job.objective ?? ''}`,
          minHeight: '1vh',
          containerClass: `${baseContainerClass}`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} text-justify whitespace-pre-wrap`,
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Funções e Responsabilidades',
          content:
            functions.length > 0 ? (
              <div>
                {functions.map((item: string, index: number) => (
                  <div key={index}>{item}</div>
                ))}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} text-justify whitespace-pre-wrap`,
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Requisitos do Cargo',
          subtitle: 'Requisitos Educacionais Obrigatórios',
          content:
            mandatoryEducationalRequirements.length > 0 ? (
              <div>
                {mandatoryEducationalRequirements.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
  ];

  const RoleExtractionSecondPage: Section[] = [
    {
      sectionsPerRow: 1,
      content: [
        {
          subtitle: 'Requisitos Educacionais Desejáveis',
          content:
            optionalEducationalRequirements.length > 0 ? (
              <div>
                {optionalEducationalRequirements.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          subtitle: 'Registros Profissionais Obrigatórios',
          content:
            mandatoryProfessionalRequirements.length > 0 ? (
              <div>
                {mandatoryProfessionalRequirements.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          subtitle: 'Requisitos Profissionais Desejáveis',
          content:
            optionalProfessionalRequirements.length > 0 ? (
              <div>
                {optionalProfessionalRequirements.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          subtitle: 'Outros Requisitos Obrigatórios',
          content:
            mandatoryOtherRequirements.length > 0 ? (
              <div>
                {mandatoryOtherRequirements.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          subtitle: 'Outros Requisitos Desejáveis',
          content:
            optionalOtherRequirements.length > 0 ? (
              <div>
                {optionalOtherRequirements.map(
                  (item: string, index: number) => (
                    <div key={index}>{item}</div>
                  ),
                )}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: `${baseContainerClass} border-t mb-8 mb-0.5`,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} pl-6 `,
          subtitleClassname: 'pl-6 text-gray-600 text-[22px]',
        },
      ],
    },
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Experiências',
          content:
            workExperience.length > 0 ? (
              <div>
                {workExperience.map((item: string, index: number) => (
                  <div key={index}>{item}</div>
                ))}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} text-justify whitespace-pre-wrap`,
        },
      ],
    },
  ];

  const RoleExtractionThirdPage: Section[] = [
    {
      sectionsPerRow: 1,
      content: [
        {
          title: 'Habilidades e Competências',
          content:
            skills.length > 0 ? (
              <div>
                {skills.map((item: string, index: number) => (
                  <div key={index}>{item}</div>
                ))}
              </div>
            ) : null,
          minHeight: '1vh',
          containerClass: baseContainerClass,
          headerClass: baseHeaderClass,
          contentClass: `${baseContentClass} text-justify whitespace-pre-wrap`,
        },
      ],
    },
  ];

  const filteredRoleExtractionFirstPage = RoleExtractionFirstPage.filter(
    (section) => !section.content.every((item) => !item.content),
  );
  const filteredRoleExtractionSecondPage = RoleExtractionSecondPage.filter(
    (section) => !section.content.every((item) => !item.content),
  );
  const filteredRoleExtractionThirdPage = RoleExtractionThirdPage.filter(
    (section) => !section.content.every((item) => !item.content),
  );

  return {
    RoleExtractionFirstPage: filteredRoleExtractionFirstPage,
    RoleExtractionSecondPage: filteredRoleExtractionSecondPage,
    RoleExtractionThirdPage: filteredRoleExtractionThirdPage,
  };
};
