import { Paper } from '@mui/material';
import { useState } from 'react';
import { Control, FieldValues, UseFieldArrayRemove } from 'react-hook-form';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import { BoardDto } from '@/pages/Board';
import api from '@/services/apiSgft';

import { ConfirmationDialog } from '../atoms/ConfirmationDialog';
import TrashButton from '../atoms/TrashButton';
import UncontrolledInputText from '../atoms/UncontrolledInputText';
import CoordinationRegister from './CoordinationRegister';

interface Props {
  index: number;
  control: Control<FieldValues, any>;
  remove: UseFieldArrayRemove;
  data?: BoardDto;
}

const ManagementRegisterCard = ({ index, control, remove, data }: Props) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const onRemove = async (id?: number) => {
    try {
      await api.delete(`new-management/${id}`);
      toast.success(`Gerência desativada com sucesso!`, {
        theme: 'colored',
        toastId: 'success',
      });
      setIsOpenDelete(false);
      queryClient.invalidateQueries(['get-all-boards-data']);
    } catch (e) {
      toast.error(`Erro ao desativar gerência!`, {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  return (
    <div className="flex w-full">
      <Paper
        sx={{
          background:
            (data && !data.active) ||
            (data?.managements[index] && !data?.managements[index].active)
              ? '#f2f2f2'
              : '#86b2ff12',
          padding: 1,
          marginBottom: 1,
          width: '96%',
        }}
      >
        <UncontrolledInputText
          title="Gerência"
          fullWidth
          {...control.register(`managements.${index}.name`)}
          uppercase
          disabled={
            (data && !data.active) ||
            (data?.managements[index] && !data?.managements[index].active)
          }
        />
        <CoordinationRegister index={index} data={data} />
      </Paper>
      <TrashButton
        color={'action'}
        onClick={() => {
          if (data?.managements[index]?.id) {
            setIsOpenDelete(true);
          } else {
            remove(index);
          }
        }}
        disabled={
          (data && !data.active) ||
          (data?.managements[index] && !data?.managements[index].active)
        }
      />
      {isOpenDelete && (
        <ConfirmationDialog
          title={'Confirmação de Desativação'}
          description={
            'Ao prosseguir com esta ação, a gerência e todas as suas informações serão desativadas no sistema.'
          }
          setIsOpen={setIsOpenDelete}
          onConfirmation={() => onRemove(data?.managements[index].id)}
          height="15rem"
          width="30rem"
        />
      )}
    </div>
  );
};

export default ManagementRegisterCard;
