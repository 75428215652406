import { atom } from 'recoil';

import {
  AllocationFormRequest,
  AllocationMotive,
} from '@/constants/AllocationRequest';

export const AllocationRequestEditData = atom<AllocationFormRequest>({
  key: 'allocationRequestEditData',
  default: {
    allocationRequestId: undefined,
    teamStructureId: 0,
    teamId: 0,
    date: new Date(),
    motive: AllocationMotive.CoberturaDeFerias,
    justification: '',
    evidence: undefined,
    files: null,
  },
});
