import { atom } from 'recoil';

import { AllocationFormRequest } from '@/constants/AllocationConstants';

export const AllocationEditData = atom<AllocationFormRequest>({
  key: 'allocationEditData',
  default: {
    allocationId: undefined,
    teamStructureId: 0,
    teamId: 0,
    date: new Date(),
    justification: '',
  },
});
