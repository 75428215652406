import { RequirementControlAtom } from '@/state/RequirementControl.atom';

import api from '../../services/apiSgft';
import { Filter } from '../../types/Filter';

const fetchFilter = async (
  field: string,
  search: string,
  atom?: RequirementControlAtom,
) => {
  const response = await api.get(`${field}`, {
    params: {
      size: 10,
      page: 1,
      search,
      employee: atom?.employee,
      role: atom?.role,
      management: atom?.management,
      areaCoordinator: atom?.areaCoordinator,
      pole: atom?.pole,
      workstation: atom?.workstation,
      status: atom?.status,
      situation: atom?.situation,
      requirementType: atom?.requirementType,
      requirementClass: atom?.requirementClass,
      requirement: atom?.requirement,
    },
  });
  return response.data.map((it: { name: string; id: string }) => ({
    label: it.name,
    value: String(it.id),
  }));
};

export const filters: Filter[] = [
  {
    key: 'pole',
    name: 'Polo',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: RequirementControlAtom) =>
      fetchFilter('requirement-control/locations', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'management',
    name: 'Gerência',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: RequirementControlAtom) =>
      fetchFilter('requirement-control/management/filter', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'areaCoordinator',
    name: 'Coordenação',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: RequirementControlAtom) =>
      fetchFilter('requirement-control/area-coordinator/filter', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'workstation',
    name: 'Local de Trabalho',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: RequirementControlAtom) =>
      fetchFilter('requirement-control/work-station/filter', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'employee',
    name: 'Colaborador',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: RequirementControlAtom) =>
      fetchFilter('requirement-control/employee/filter', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'role',
    name: 'Função',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: RequirementControlAtom) =>
      fetchFilter('requirement-control/roles/filter', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'requirementType',
    name: 'Tipo de Requisito',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: RequirementControlAtom) =>
      fetchFilter('requirement-control/requirement-type/filter', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'requirementClass',
    name: 'Classe',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: RequirementControlAtom) =>
      fetchFilter(
        'requirement-control/requirement-type/class/filter',
        search,
        atom,
      ),
    isDisabled: () => false,
  },
  {
    key: 'requirement',
    name: 'Requisito',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: RequirementControlAtom) =>
      fetchFilter('requirement-control/requirement/filter', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'status',
    name: 'Status',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: RequirementControlAtom) =>
      fetchFilter('requirement-control/status/filter', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'situation',
    name: 'Situação do requisito',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: RequirementControlAtom) =>
      fetchFilter('requirement-control/situation/filter', search, atom),
    isDisabled: () => false,
  },
];
