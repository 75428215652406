import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { queryClient } from '@/App';
import {
  isOnDutyWorker,
} from '@/utils/handleSavePermissions';

import SimpleMenu from '../atoms/Menu';
import AllocationModal from '../organisms/AllocationModal';
import ExtraTripsModal from '../organisms/ExtraTripsModal';
import JourneyExtensionModal from '../organisms/JourneyExtensionModal';
import OnNoticeActivationModal from '../organisms/OnNoticeActivationModal';
import OnNoticeModal from '../organisms/OnNoticeModal';
import VacationRegisterModal from '../organisms/VacationRegisterModal';

interface ViewScheduleMenuProps {
  employeeId: number;
  onNotice: boolean;
  disabled?: boolean;
  iconComponent?: React.ReactNode;
  admissionDate: string;
}
type ModalType =
  | 'ferias'
  | 'ida-extra'
  | 'sobreaviso'
  | 'extensao-jornada'
  | 'acionamento de sobreaviso'
  | 'alocações'
  | 'alias'
  | null;
const ViewScheduleMenu = ({
  employeeId,
  onNotice,
  disabled,
  iconComponent,
}: ViewScheduleMenuProps) => {
  const [searchParams] = useSearchParams();
  const modalType = searchParams.get('tipo') as ModalType;
  const vacationId = Number(searchParams.get('ferias'));
  const [unChangedEmployeeId, setUnChangedEmployeeId] = useState<
    number | undefined
  >(undefined);

  const [isVacationRegisterModalOpen, setIsVacationRegisterModalOpen] =
    useState(
      Number(searchParams.get('colaborador')) === employeeId &&
        modalType === 'ferias',
    );
  const [isExtraTripsOpen, setIsExtraTripsOpen] = useState(
    Number(searchParams.get('colaborador')) === employeeId &&
      modalType === 'ida-extra',
  );

  const [isOnNoticeOpen, setIsOnNoticeOpen] = useState(
    Number(searchParams.get('colaborador')) === employeeId &&
      modalType === 'sobreaviso',
  );

  const [isJourneyExtensionOpen, setIsJourneyExtensionOpen] = useState(
    Number(searchParams.get('colaborador')) === employeeId &&
      modalType === 'extensao-jornada',
  );

  const [isTriggerOnNoticeOpen, setIsTriggerOnNoticeOpen] = useState(
    Number(searchParams.get('colaborador')) === employeeId &&
      modalType === 'acionamento de sobreaviso',
  );

  const [isAllocationOpen, setIsAllocationOpen] = useState(
    Number(searchParams.get('colaborador')) === employeeId &&
      modalType === 'alocações',
  );
  useEffect(() => {
    if (unChangedEmployeeId === undefined || !isAllocationOpen) {
      setUnChangedEmployeeId(employeeId);
    }
  }, [employeeId, isAllocationOpen]);

  const shouldDisable = isOnDutyWorker() || false;

  const options = [
    {
      name: 'Férias e Cobertura',
      onClick: () => setIsVacationRegisterModalOpen(true),
      disabled: shouldDisable,
    },
    {
      name: 'Sobreaviso',
      onClick: () => {
        setIsOnNoticeOpen(true);
      },
      disabled: !onNotice || shouldDisable,
    },
    {
      name: 'Acionamento de Sobreaviso',
      onClick: () => setIsTriggerOnNoticeOpen(true),
      disabled: !onNotice,
    },
    // {
    //   name: 'Idas Extras ao Polo',
    //   onClick: () => setIsExtraTripsOpen(true),
    //   disabled: true && shouldDisable, // TODO: DESCOMENTAR QUANDO SUBIR IDA EXTRA
    // },
    {
      name: 'Extensão de Jornada',
      onClick: () => setIsJourneyExtensionOpen(true),
      disabled: shouldDisable,
    },
    {
      name: 'Realocação',
      onClick: () => {
        setIsAllocationOpen(true);
      },
      disabled: shouldDisable,
    },
  ];

  return (
    <>
      {isVacationRegisterModalOpen && (
        <VacationRegisterModal
          employeeId={employeeId}
          vacationId={vacationId}
          modalIsOpen={isVacationRegisterModalOpen}
          handleClose={() => setIsVacationRegisterModalOpen(false)}
        />
      )}
      {isOnNoticeOpen && onNotice && (
        <OnNoticeModal
          employeeId={employeeId}
          modalIsOpen={isOnNoticeOpen}
          handleClose={() => setIsOnNoticeOpen(false)}
        />
      )}
      {isTriggerOnNoticeOpen && onNotice && (
        <OnNoticeActivationModal
          employeeId={employeeId}
          modalIsOpen={isOnNoticeOpen}
          handleClose={() => setIsTriggerOnNoticeOpen(false)}
        />
      )}
      {isExtraTripsOpen && (
        <ExtraTripsModal
          employeeId={employeeId}
          modalIsOpen={isVacationRegisterModalOpen}
          handleClose={() => setIsExtraTripsOpen(false)}
        />
      )}
      {isJourneyExtensionOpen && (
        <JourneyExtensionModal
          employeeId={employeeId}
          modalIsOpen={isJourneyExtensionOpen}
          handleClose={() => setIsJourneyExtensionOpen(false)}
        />
      )}
      <SimpleMenu
        iconComponent={iconComponent}
        options={options}
        disabled={disabled}
      />
      {isAllocationOpen && (
        <AllocationModal
          employeeId={unChangedEmployeeId}
          handleClose={() => {
            setIsAllocationOpen(false);
            setUnChangedEmployeeId(undefined);
            queryClient.invalidateQueries(['team-structure']);
            queryClient.invalidateQueries(['all-employees']);
          }}
        />
      )}
    </>
  );
};
export default ViewScheduleMenu;
